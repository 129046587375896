import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { variations } from '.';

interface Props {
  $isVisible?: boolean;
  $variation?: (typeof variations)[number];
  showLine?: boolean;
  footerVisible?: boolean;
}

export const Container = styled(motion.div)<Pick<Props, '$variation'>>`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
    $variation = 'fullscreen',
  }) => css`
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    ${$variation !== 'fullscreen' &&
    css`
      align-items: center;
      display: flex;
      justify-content: center;
    `}

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      z-index: 11;
    }
  `}
`;

export const Wrapper = styled(motion.div)<Pick<Props, '$variation'>>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      borderRadius,
      colors,
    },
    $variation = 'fullscreen',
  }) => css`
    background: ${colors.brandLight};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${$variation === 'large' &&
    css`
      width: 42rem;
    `}

    ${$variation === 'medium' &&
    css`
      width: 30rem;
    `}

    ${$variation === 'small' &&
    css`
      width: 18rem;
    `}

    ${$variation !== 'fullscreen' &&
    css`
      border-radius: ${borderRadius.sm};
      overflow: hidden;
      z-index: 11;
    `}
    ${$variation === 'fullscreen' &&
    css`
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
    `}

    ::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: white;
    }

    ::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      background-color: ${colors.brandDarkAlpha20};
      border-radius: 8px;
      height: 8px;
      -webkit-border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #bbb;
    }

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      overflow: hidden;
    }
  `}
`;

export const Header = styled.header<Pick<Props, '$variation' | 'showLine'>>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
    },
    showLine,
    $variation,
  }) => css`
    align-items: center;
    background: ${colors.brandLight};
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 1.5rem;
    width: 100%;
    z-index: 1;

    .heading {
      line-break: anywhere;
    }

    ${showLine &&
    css`
      box-shadow: 0 1px 0 ${colors.brandDarkAlpha20};
    `}

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      ${$variation === 'fullscreen' &&
      css`
        padding: 0.5rem;
        padding-left: 1.5rem;
      `}
    }
  `}
`;

export const Content = styled.div<Pick<Props, '$variation'>>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
    },
    $variation = 'fullscreen',
  }) => css`
    align-items: center;
    background: ${colors.brandLight};
    display: flex;
    flex: 1;
    justify-content: center;
    word-break: break-word;

    ${$variation !== 'fullscreen' &&
    css`
      align-items: flex-start;
      max-height: 75vh;
      max-height: 75svh;
      overflow-y: auto;
      padding: 1px 1.5rem 1.5rem;
    `}

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      box-sizing: border-box;
      display: block;
      margin: 0;
      max-width: 100%;
      min-width: 0;
      overflow-y: auto;

      .modal-heading {
        margin-bottom: 1.5rem;
        margin-left: 1.5rem;
      }

      ::-webkit-scrollbar {
        height: 8px;
        width: 8px;
      }

      ::-webkit-scrollbar-track {
        background: white;
      }

      ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: ${colors.brandDarkAlpha20};
        border-radius: 8px;
        height: 8px;
        -webkit-border-radius: 8px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #bbb;
      }
    }
  `}
`;

export const Footer = styled.footer<Pick<Props, 'showLine' | 'footerVisible'>>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
    },
    showLine,
    footerVisible,
  }) => css`
    box-sizing: border-box;
    display: none;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 1rem 1rem 1.5rem;
    width: 100%;
    background-color: ${colors.brandLight};

    ${showLine &&
    css`
      box-shadow: 0 0 1px 1px ${colors.brandDarkAlpha20};
    `}

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      display: flex;
      visibility: visible;
    }

    ${footerVisible &&
    css`
      display: flex;
      visibility: visible;
    `}
  `}
`;

export const Backdrop = styled(motion.div)<Pick<Props, '$variation'>>`
  ${({ theme: { colors }, $variation = 'fullscreen' }) => css`
    ${$variation !== 'fullscreen' &&
    css`
      background: ${colors.brandDarkAlpha90};
      cursor: pointer;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10;
    `}
  `}
`;
