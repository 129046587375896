/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import { Select, Spinner } from '@sendible/design-system';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTikTokVideos } from '@sendible/frontendv2/src/models/reports';
import { Container, CustomText, MotionButton, PaginationSection, SectionOptions, Slider, SliderContainer, WarningWrapper } from './index.styles';
import { TikTokVideoCard } from './components/TikTokVideoCard';
import { useReportsContext } from '../../../context';
import { Warning } from '../Warning';
import { useTikTokReportContext } from '../../context';

export type VideoFilterType = 'engagement' | 'sources' | 'watch_metrics';

interface FilterOption {
  label: string;
  action: VideoFilterType;
}

export const Videos = () => {
  const { t } = useTranslation(['reports', 'common_terms']);

  const filterOptions: FilterOption[] = [
    { label: t('report_engagement_sidebar_link'), action: 'engagement' },
    { label: t('watch_metrics'), action: 'watch_metrics' },
    { label: t('sources'), action: 'sources' },
  ];
  const { isPrint } = useReportsContext();
  const [filter, setFilter] = useState<FilterOption>(filterOptions[0]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  const sliderRef = useRef<HTMLDivElement>(null);
  const { currentAccountObject } = useTikTokReportContext();
  const videosData = useGetTikTokVideos();
  const { data: videos, isLoading, isError, isRefetching } = videosData;

  const isEmpty = videos?.length === 0;

  const gapBetweenMediaCards = 32;
  const mediaCardWidth = 240;
  const offset = (sliderRef.current?.offsetParent as HTMLElement)?.offsetWidth ? (sliderRef.current?.offsetParent as HTMLElement)?.offsetWidth : 0;
  const totalVideoSpace = mediaCardWidth + gapBetweenMediaCards;
  const videosPerPage = Math.floor(offset / totalVideoSpace);

  const isGettingData = isLoading || isRefetching;

  const handleFilter = (action: VideoFilterType) => {
    const item = filterOptions.find((filterOption) => filterOption.action === action);

    if (item) setFilter(item);
  };

  useEffect(() => {
    setPages(videos ? videos.length - videosPerPage : 0);
  }, [offset, videos]);

  const content = (
    <>
      {isError ? (
        <WarningWrapper>
          <Warning
            color="semanticDangerShade20"
            text={t('generic_report_error')}
            type="danger"
          />
        </WarningWrapper>
      ) : isGettingData ? (
        <WarningWrapper>
          <Spinner size="lg" />
        </WarningWrapper>
      ) : isEmpty ? (
        <WarningWrapper>
          <Warning
            color="brandDarkTint50"
            text={t('videos_error')}
            type="video_off"
          />
        </WarningWrapper>
      ) : (
        <>
          {videos?.map((video) => (
            <TikTokVideoCard
              activeMetric={filter.action}
              caption={video.caption || ''}
              displayName={video.displayName}
              engagement={video.engagement}
              impressions={video.impressions}
              key={video.videoId}
              postedDate={video.postDate}
              profileAvatar={currentAccountObject?.avatar || ''}
              shareUrl={video.shareUrl || ''}
              thumbnailUrl={video.thumbnail || ''}
              userName={video.username}
              videoId={String(video.videoId)}
              watchMetrics={video.watchMetrics}
            />
          ))}
        </>
      )}
    </>
  );

  return (
    <Container>
      <SectionOptions>
        <CustomText variation="body_14">{t('videos_text_1')}</CustomText>
        <Select
          disabled={isGettingData}
          onSelect={(option) => handleFilter(option as VideoFilterType)}
          options={filterOptions.map(({ action, label }) => {
            return { action, label };
          })}
          selectedOption={filter.label}
        />
        <CustomText variation="body_14">{t('videos_text_2')}</CustomText>
      </SectionOptions>
      <SliderContainer>
        <>
          {pages > 1 && !isPrint && (
            <AnimatePresence>
              {page > 0 ? (
                <MotionButton
                  key="prev-button"
                  className="attach-prev nav-button"
                  icon="arrow_left"
                  onClick={() =>
                    setPage((prev) => {
                      if (prev === 0) return prev;

                      return prev - 1;
                    })
                  }
                  size={16}
                  initial={{ x: '-5rem' }}
                  animate={{ x: 0 }}
                  exit={{ x: '-5rem' }}
                />
              ) : null}
              {page < pages ? (
                <MotionButton
                  key="next-button"
                  className="attach-next nav-button"
                  icon="arrow_right"
                  onClick={() =>
                    setPage((prev) => {
                      if (prev >= pages) return prev;

                      return prev + 1;
                    })
                  }
                  size={16}
                  initial={{ x: '5rem' }}
                  animate={{ x: 0 }}
                  exit={{ x: '5rem' }}
                />
              ) : null}
            </AnimatePresence>
          )}
          <Slider
            ref={sliderRef}
            animate={sliderRef.current ? { x: -page * totalVideoSpace } : {}}
            transition={{ ease: 'easeInOut' }}
          >
            {content}
          </Slider>
        </>
      </SliderContainer>

      {!!videos?.length && pages > 1 && !isError && (
        <PaginationSection>
          <CustomText variation="body_14">
            {page + 1} {t('of', { ns: 'common_terms' })} {Math.ceil(pages + 1)} {t(pages > 1 ? 'pages' : 'page', { ns: 'common_terms' })}
          </CustomText>
        </PaginationSection>
      )}
    </Container>
  );
};
