import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadTranslations } from './i18n';
import { MediaSystemComponents } from './components/MediaSystemComponents';
import { AddProfileModalPrompt } from './components';
import { useBackgroundUploaderContext } from './components/BackgroundUploader/context';
import { ComposeBoxProvider } from './composeBoxContext';
import BlueskyConnection from './components/BlueskyConnection';

export const FrontendV2Components = () => {
  const [sharedState] = useBridgeContext();
  const {
    closeUploader,
    cancelUpload,
    composeBoxSkeletons,
    uploadingIdsFromMediaLibrary,
    setComposeBoxUploadedFileIds,
    setUploadingIdsFromComposeBox,
  } = useBackgroundUploaderContext();
  const navigate = useNavigate();

  useEffect(() => {
    loadTranslations(sharedState?.user?.language);
  }, [sharedState?.user?.language]);

  useEffect(() => {
    composeBoxSkeletons.forEach(({ id, status }) => status !== 'UploadFailed' && cancelUpload(id));

    setComposeBoxUploadedFileIds([]);
    setUploadingIdsFromComposeBox([]);

    if (!uploadingIdsFromMediaLibrary.length) {
      closeUploader();
    }
  }, [sharedState?.compose?.isComposeBoxOpen, navigate]);

  const isComposeBoxOpen = sharedState?.compose?.isComposeBoxOpen;
  const addThreadsPromptOpenMode = sharedState?.app?.addThreadsPromptOpenMode;
  const addTikTokPromptOpenMode = sharedState?.app?.addTikTokPromptOpenMode;
  const isBlueskyModalOpen = sharedState?.app?.isBlueskyModalOpen;

  return (
    <>
      {addThreadsPromptOpenMode && (
        <AddProfileModalPrompt
          serviceType="threads"
          mode={addThreadsPromptOpenMode}
        />
      )}
      {addTikTokPromptOpenMode && (
        <AddProfileModalPrompt
          serviceType="tiktok"
          mode={addTikTokPromptOpenMode}
        />
      )}
      {window?.js_featureTagsManager?.isFeatureTag('mediaSystem') && isComposeBoxOpen ? (
        <ComposeBoxProvider>
          <MediaSystemComponents />
        </ComposeBoxProvider>
      ) : null}
      {isBlueskyModalOpen ? <BlueskyConnection /> : null}
    </>
  );
};
