import { helpers, useToastNotificationContext } from '@sendible/design-system/src';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { createContext, useContext, useEffect, useState } from 'react';
import { NotificationTypeEnum } from '@sendible/design-system/src/components/molecules/Toast';
import { useTranslation } from 'react-i18next';
import { EVENTS, subscribe } from '@sendible/event-manager';
import { useUploadFiles } from './models/uploads';
import { composeMediaInteractions } from './composeMediaInteractions';
import { mediaSystem } from './components/MediaAttached/pendoEvents';
import { supportedFileTypesRegex } from './constants/files';

export type ComposeBoxContextType = {
  isReplaceModalOpen: boolean;
  setIsReplaceModalOpen: (state: boolean) => void;
  handleFileUpload: (files: File[], source: string) => Promise<void>;
};

export const ComposeBoxContext = createContext({} as ComposeBoxContextType);

const checkMediaTypeConflicts = (files: File[]) => {
  const MediaType = files[0].type;

  if (composeMediaInteractions.getNumberOfAttachedMedia() === 0) {
    return false;
  }

  if (MediaType.includes('video')) {
    return true;
  }

  return !composeMediaInteractions.hasAttachedTypeOf(MediaType);
};

export const ComposeBoxProvider = ({ children }: Component) => {
  const [sessionId, setSessionId] = useState<string>(helpers.generateId());
  const { showToastNotification } = useToastNotificationContext();
  const { uploadFiles, getValidSingleTypeFiles } = useUploadFiles({ uploadingFrom: 'compose-box' });
  const [isReplaceModalOpen, setIsReplaceModalOpen] = useState(false);
  const [sharedState] = useBridgeContext();
  const { t } = useTranslation('common_terms');
  const { t: cbt } = useTranslation('compose_box');

  const showConfirmationToReplaceMedia = async (): Promise<boolean> => {
    setIsReplaceModalOpen(true);

    return new Promise((resolve) => {
      let unsubscribeReplace: () => void;
      let unsubscribeCancel: () => void;

      // eslint-disable-next-line prefer-const
      unsubscribeReplace = subscribe(EVENTS.MODAL_REPLACE_ATTACHED_MEDIA, () => {
        unsubscribeReplace();
        unsubscribeCancel();
        resolve(true);
      });

      unsubscribeCancel = subscribe(EVENTS.MODAL_CANCEL_REPLACE, () => {
        unsubscribeReplace();
        unsubscribeCancel();
        resolve(false);
      });
    });
  };

  const uploadMediaFiles = async (files: File[], source: string) => {
    const mediaTypes = files ? Array.from(files).map(({ type }) => type) : [];

    window.pendo.track(mediaSystem.mediasAttachedToComposeBox, {
      session_id: sessionId,
      media_count: files.length,
      media_source: source,
      media_types: mediaTypes,
    });

    uploadFiles(Array.from(files));
  };

  const checkIfTypesAreValid = (files: File[]) => {
    const fileTypes = Object.entries(files).map((file) => file[1].type.toLowerCase());
    const allFilesAreValid = fileTypes.every((type) => supportedFileTypesRegex.test(type));

    if (allFilesAreValid) return true;

    return false;
  };

  const handleFileUpload = async (files: File[], source: string) => {
    const typesAreValid = checkIfTypesAreValid(files);

    if (!typesAreValid) {
      showToastNotification({
        notificationText: t('upload_file_error') as string,
        type: NotificationTypeEnum.Error,
        showCloseButton: true,
        hideTimeout: 15,
      });

      return;
    }

    const singleTypeFiles = getValidSingleTypeFiles(Array.from(files));

    if (singleTypeFiles.length !== files.length) {
      showToastNotification({
        notificationText: cbt('mixedMediaError') as string,
        type: NotificationTypeEnum.Error,
        showCloseButton: true,
        hideTimeout: 15,
      });
    }

    const hasConflictingTypes = checkMediaTypeConflicts(singleTypeFiles);

    if (hasConflictingTypes) {
      setIsReplaceModalOpen(true);

      const replaceConfirmed = await showConfirmationToReplaceMedia();

      if (replaceConfirmed) {
        composeMediaInteractions.removeAllMediaFromActiveTab();
        await uploadMediaFiles(singleTypeFiles, source);
      }

      return;
    }

    await uploadMediaFiles(singleTypeFiles, source);
  };

  useEffect(() => {
    if (sharedState.compose.isComposeBoxOpen) setSessionId(helpers.generateId());
  }, [sharedState?.compose?.isComposeBoxOpen]);

  return (
    <ComposeBoxContext.Provider
      value={{
        setIsReplaceModalOpen,
        isReplaceModalOpen,
        handleFileUpload,
      }}
    >
      {children}
    </ComposeBoxContext.Provider>
  );
};

export const useComposeBoxContext = (): ComposeBoxContextType => useContext(ComposeBoxContext);
