import styled, { css } from 'styled-components';

export const Container = styled.div<{ $hideBorder?: boolean }>`
  ${({ theme: { colors, fontSizes, fontWeights }, $hideBorder }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0;

    ${!$hideBorder &&
    css`
      border-bottom: 1px solid ${colors.brandDarkTint90};
    `}

    p {
      color: ${colors.brandDarkTint30};
      font-size: ${fontSizes.s12};
      line-height: 1.33em;
      letter-spacing: 0.3px;

      b {
        color: ${colors.brandDark};
        font-weight: ${fontWeights.regular};
      }
    }
  `}
`;
