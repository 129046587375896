import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`
    ${({
      theme: {
        breakPoints: { smallDevices },
        colors,
      },
    }) => css`
      body.v2-app {
        & #v2 {
          padding-top: 83px !important;
        }
      }

      body.v2-app.critical-error-present.payment-error {
        & #v2 {
          padding-top: 131px !important;
        }
      }

      .v2-html {
        height: 100%;
      }

      .v2-html body {
        height: 100%;
        margin: 0;
      }

      .v2-html body #root {
        height: 100%;
      }

      #modal-root {
        position: fixed;
        z-index: 3000;
        top: 0;
      }

      #lightbox-root {
        position: fixed;
        z-index: 2999;
      }

      #dropdown-root {
        position: fixed;
        z-index: 3001;
      }

      #popover-root {
        position: fixed;
        z-index: 3002;
      }

      #v2 {
        box-sizing: border-box;
        color: ${colors.brandDark};
        scroll-behavior: smooth;
        -ms-text-size-adjust: 100%;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;

        #left-sidebar {
          font-family: inherit;
          z-index: 4;
        }

        .input {
          input {
            margin: 0;
          }
        }

        .checkbox {
          margin: 0;
          min-height: unset;
          padding-left: unset;
          line-height: unset;
          font-weight: unset;

          input {
            margin: unset;
            float: unset;
          }
        }

        body :not(#left-sidebar) {
          font-family: 'IBM Plex Sans', sans-serif;
        }

        input,
        textarea,
        button {
          font-family: 'IBM Plex Sans', sans-serif;
          -moz-osx-font-smoothing: grayscale;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          -webkit-tap-highlight-color: transparent;
          -webkit-font-smoothing: antialiased;
        }

        input,
        input:before,
        input:after {
          -webkit-user-select: initial;
          -khtml-user-select: initial;
          -moz-user-select: initial;
          -ms-user-select: initial;
          user-select: initial;
        }
      }

      @media all and (${smallDevices.max}) {
        body {
          min-width: unset;
        }

        .ReactQueryDevtools {
          display: none !important;
        }

        #v2 {
          overflow-y: auto;
        }
      }
    `}
`;
