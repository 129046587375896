import { useTranslation } from 'react-i18next';
import { ColorIndicator, Container, Item, Label } from './index.styles';

export const Legend = () => {
  const { t } = useTranslation('reports');
  const engagementColors = {
    Low: '#0075DB',
    High: '#E10543',
    Medium: '#000',
  };

  return (
    <Container className="legend">
      <Item>
        <ColorIndicator
          style={{
            background: engagementColors.Low,
          }}
        />
        <Label>{t('low')}</Label>
      </Item>
      <Item>
        <ColorIndicator
          style={{
            background: engagementColors.Medium,
          }}
        />
        <Label>{t('medium')}</Label>
      </Item>
      <Item>
        <ColorIndicator
          style={{
            background: engagementColors.High,
          }}
        />
        <Label>{t('high')}</Label>
      </Item>
    </Container>
  );
};
