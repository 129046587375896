import { urls } from '@sendible/common';
import { useBridgeContext } from '@sendible/shared-state-bridge';

export const useServiceConnection = () => {
  const [{ user }] = useBridgeContext();

  const openConnectionWindow = (connectionUrl: string) => {
    const w = 650;
    const h = 450;
    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;

    window.open(
      connectionUrl,
      '_blank',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`
    );
  };

  const connectToService = (serviceId: string) => {
    const connectionUrl = `${urls.baseUrl}/api/v2/connect?parent_domain=${encodeURIComponent(
      `${window.location.protocol}//${window.location.hostname}`
    )}&service_id=${serviceId}&pricingEnabled=true`;

    let urlWithContext = `${connectionUrl}&username=${user.username}&access_token=${user.accessToken}`;

    if (user.isSwitchedUser) {
      urlWithContext = `${connectionUrl}&username=${user.username}&logged_in_access_token=${user.accessToken}`;
    }
    openConnectionWindow(urlWithContext);
  };

  return { connectToService };
};
