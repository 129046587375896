import { Box, Button, Modal, Text } from '@sendible/design-system';
import { useTranslation } from 'react-i18next';
import { EVENTS, publish } from '@sendible/event-manager';
import { useComposeBoxContext } from '../../composeBoxContext';

export const ConfirmReplaceMediaModal = () => {
  const { setIsReplaceModalOpen } = useComposeBoxContext();

  const { t: cbt } = useTranslation('compose_box');
  const { t: ct } = useTranslation('common_terms');

  return (
    <Modal
      close={() => {
        publish(EVENTS.MODAL_CANCEL_REPLACE, {});
        setIsReplaceModalOpen(false);
      }}
      headingTitle={cbt('replaceConfirmTitle') as string}
      variation="small"
    >
      <Box>
        <Text>{cbt('replaceConfirm')}</Text>
        <Box
          contentAlignment="stretch"
          orientation="column"
          verticalGap="s8"
          flex={1}
          margin={['s24', 's0', 's0', 's0']}
        >
          <Button
            onClick={() => {
              publish(EVENTS.MODAL_CANCEL_REPLACE, {});
              setIsReplaceModalOpen(false);
            }}
            label={ct('cancel') as string}
            size={16}
            data-testid="modal-button-cancel"
            variation="outline"
            autoFocus
          />
          <Button
            appearance="primary"
            onClick={() => {
              publish(EVENTS.MODAL_REPLACE_ATTACHED_MEDIA, {});
              setIsReplaceModalOpen(false);
            }}
            label={ct('replace') as string}
            size={16}
            data-testid="modal-button-replace"
            variation="fill"
          />
        </Box>
      </Box>
    </Modal>
  );
};
