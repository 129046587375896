import { useEffect, useRef } from 'react';
import { ChildrenBox, Container, Data, IconBox, Option, Sel } from './index.styles';
import { Text } from '../../atoms/Text';
import { Heading } from '../../atoms/Heading';
import { Icon } from '../../atoms/Icon';
import useWindowSize from '../../utils/useWindowSize';
import { MOBILE_MAX_SIZE } from '../../utils/constants';

interface OptionItem {
  label: string;
  action: string;
}

export interface SelectProps extends Component {
  /**
   * Disable interactions. Optional.
   */
  disabled?: boolean;
  /**
   * Secondary Text. Required.
   */
  extraText?: string;
  /**
   * Select event emmiter. Required.
   */
  onSelect: (option: string) => void;
  /**
   * List of selectable options. Required.
   */
  options: OptionItem[];
  /**
   * Currently selected option. Required.
   */
  selectedOption: string;
  /**
   * Hide text section on mobile. Optional.
   */
  shrinkOnMobile?: boolean;
}

export const Select = ({
  children,
  className,
  customClass,
  disabled = false,
  id,
  extraText,
  onSelect,
  options,
  selectedOption,
  shrinkOnMobile = false,
  testId,
}: SelectProps) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const { width } = useWindowSize();

  const showLabels = width > MOBILE_MAX_SIZE || !shrinkOnMobile || !children;

  const optionList = options.map((option) => (
    <Option
      data-testid={`${testId}-option-${option.label}`}
      key={option.label}
      value={option.label}
    >
      {option.label}
    </Option>
  ));

  useEffect(() => {
    if (selectRef.current) selectRef.current.value = selectedOption;
  }, []);

  return (
    <Container
      className={`select ${customClass} ${className}`}
      data-testid={testId}
      id={id}
      $hasChildren={!!children}
      $shrink={!showLabels}
    >
      <Sel
        disabled={disabled}
        onChange={(e) => onSelect(options.find(({ label }) => e.target.value === label)?.action || '')}
        data-testid={`${testId}-select`}
        ref={selectRef}
      >
        {optionList}
      </Sel>
      <ChildrenBox tabIndex={-1}>{children}</ChildrenBox>
      {showLabels && (
        <Data tabIndex={-1}>
          <Heading
            semantic="h6"
            variation="component-title_14"
          >
            {selectedOption}
          </Heading>
          {extraText && (
            <Text
              color="brandDarkTint30"
              variation="body_12"
            >
              {extraText}
            </Text>
          )}
        </Data>
      )}
      <IconBox tabIndex={-1}>
        <Icon
          color="brandDark"
          name="select"
        />
      </IconBox>
    </Container>
  );
};
