import { Box } from '@sendible/design-system/src';
import styled from 'styled-components';
import { HeaderContainer } from './components/Header/index.styles';
import { PaginationContainer } from './components/index.styles';
import { SplitAndGrowthContainer } from './components/AudienceGrowth/index.styles';
import { TopPostsOrderContainer } from './components/TopPosts/index.styles';
import { ReportWidth } from '../consts';

export const CampaignsReportContainer = styled.div`
  position: relative;
  width: 100%;

  &.print {
    left: 0;
    position: absolute;
    top: 0;
    width: ${ReportWidth}px;
  }

  &.print ${HeaderContainer} {
    display: none !important;
  }

  &.print ${PaginationContainer} {
    display: none !important;
  }

  &.print ${SplitAndGrowthContainer} {
    @media (max-width: 1000px) {
      flex-direction: row;

      > div {
        width: 49%;
      }
    }
  }

  &.print ${TopPostsOrderContainer} {
    display: none !important;
  }
`;

export const ReportLoadingOverlay = styled.div`
  align-items: center;
  background: white;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: sticky;
  width: inherit;
  z-index: 9999;
`;

export const ReportMessageContainer = styled(Box)`
  box-sizing: border-box;
  justify-content: center;
  padding: 3rem;
  width: 100%;
`;

export const NotCampaignsContainer = styled(Box)`
  width: 100%;
  flex-direction: column;
  padding: 14px 20px;
`;

export const NotCampaignsErrorContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  gap: 2rem;

  svg {
    width: 3rem;
    height: 3rem;
  }
`;
