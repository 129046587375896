type WeekdayOrderType = {
  [key: string]: number;
  Sun: 0;
  Mon: 1;
  Tue: 2;
  Wed: 3;
  Thu: 4;
  Fri: 5;
  Sat: 6;
};

export const weekdayOrder: WeekdayOrderType = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
};

export const makeNumbersReadFriendly = (number: number, fraction?: number): string =>
  number.toLocaleString('en', {
    notation: 'compact',
    maximumFractionDigits: fraction || 1,
  });

export const findMedian = (arr: number[]) => {
  arr.sort((a, b) => a - b);

  const middleIndex = Math.floor(arr.length / 2);

  if (arr.length % 2 === 0) {
    return (arr[middleIndex - 1] + arr[middleIndex]) / 2;
  }

  return arr[middleIndex];
};

export const internationalizeNumberHourOfDay = (hourOfDay: number, language: string) => {
  const dummyDate = new Date();

  dummyDate.setHours(hourOfDay, 0, 0, 0);

  const label = dummyDate.toLocaleTimeString(language, { hourCycle: 'h12', hour: '2-digit' }).toUpperCase();

  return label[0] === '0' ? label.substring(1) : label;
};
