import React from 'react';
import ReactDOM from 'react-dom';
import Orchestrator from './Orchestrator';
import './tracking-scripts';

ReactDOM.render(
  <React.StrictMode>
    <Orchestrator />
  </React.StrictMode>,
  document.getElementById('root')
);
