import { IconProps, Icon, Text } from '@sendible/design-system/src';
import { Container } from './index.styles';

interface WarningProps {
  color: IconProps['color'];
  text: string;
  type: IconProps['name'];
}

export const Warning = ({ color = 'semanticDangerShade20', text, type = 'info' }: WarningProps) => (
  <Container className="report-warning">
    <div>
      <Icon
        name={type}
        color={color}
      />
      &nbsp;<Text>{text}</Text>
    </div>
  </Container>
);
