import { ResponsiveLine, Serie } from '@nivo/line';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { makeNumbersReadFriendly } from '@sendible/frontendv2/src/utils';
import { Tooltip } from './Tooltip';
import { useReportsContext } from '../../../../context';
import { getChartLeftMargin } from '../../../../helpers';
import { useTikTokReportContext } from '../../../context';

export const formatDay = (date: Date) => {
  return date.getDate().toString().padStart(2, '0');
};

type ChartProps = {
  timeSeriesData: Serie[];
  isSmallScreen: boolean;
};

export const Chart = ({ timeSeriesData, isSmallScreen }: ChartProps) => {
  const { userOffset } = useTikTokReportContext();
  const { isPrint } = useReportsContext();
  const maxLegends = 7;
  const isBigList = timeSeriesData[0].data.length > maxLegends;
  const [
    {
      user: { language },
    },
  ] = useBridgeContext();
  const getDateValue = (string: string) => {
    const dt = new Date(string);

    dt.setHours(dt.getHours() + userOffset / 60);

    return new Intl.DateTimeFormat(language, { day: 'numeric', month: 'short' }).format(dt);
  };

  const mappedDataNumbers = timeSeriesData[0].data.map(({ y }) => y) as number[];

  const biggestValue = Math.max(...mappedDataNumbers);
  const smallestValue = Math.min(...mappedDataNumbers);
  const marginLeft = getChartLeftMargin(biggestValue, smallestValue);

  return (
    <ResponsiveLine
      data={timeSeriesData}
      margin={{
        top: isSmallScreen ? 50 : 20,
        right: isSmallScreen ? 0 : 120,
        bottom: 24,
        left: marginLeft,
      }}
      xScale={{
        type: 'point',
      }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        clamp: true,
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: timeSeriesData[0].data.filter((_, index) => (isBigList ? index % maxLegends === 0 : true)).map(({ x }) => x),
        format: (e) => getDateValue(e),
        legend: '',
        legendOffset: 36,
        legendPosition: 'middle',
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: isSmallScreen ? 4 : 8,
        legend: '',
        legendOffset: -40,
        legendPosition: 'middle',
        truncateTickAt: 0,
        format: (v) => makeNumbersReadFriendly(v),
      }}
      animate={false}
      enableGridX={false}
      colors={['#000000', '#0075DB', '#E10543']}
      lineWidth={isBigList ? 2 : 4}
      enablePoints={false}
      enableArea
      areaBlendMode={isPrint ? undefined : 'multiply'}
      enableCrosshair={false}
      useMesh
      tooltip={Tooltip}
      legends={
        !isSmallScreen
          ? [
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemDirection: 'left-to-right',
                itemWidth: 100,
                itemHeight: 24,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
              },
            ]
          : [
              {
                anchor: 'top-left',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -32,
                itemsSpacing: 2,
                itemDirection: 'left-to-right',
                itemWidth: 100,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
              },
            ]
      }
    />
  );
};
