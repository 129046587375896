import i18n, { changeLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';

const supportedLanguages = ['en', 'sv', 'zh', 'de', 'es', 'fr', 'nl', 'cs', 'ja', 'it', 'pt'];

const loadNamespace = async (lng: string, ns: string) => {
  const module = await import(`@sendible/localizationv2/src/translations/${lng}/${ns}.json`);

  return module.default;
};

const loadTranslations = (lng?: string) => {
  const language = lng || navigator.language.split('-')[0];
  const languageToLoad = supportedLanguages.includes(language) ? language : 'en';

  changeLanguage(languageToLoad);

  const namespaces = ['ai_assist', 'bluesky', 'campaigns', 'common_terms', 'compose_box', 'media_library', 'reports', 'sendible360'];

  namespaces.forEach(async (ns) => {
    const translations = await loadNamespace(languageToLoad, ns);

    i18n.addResourceBundle(languageToLoad, ns, translations, true, true);
  });
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: false,
  interpolation: { escapeValue: false },
});

export { loadTranslations };
export default i18n;
