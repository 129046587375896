import { fetchWithHeaders } from '@sendible/common';
import { Box, Button, Input, Modal, Text, useToastNotificationContext } from '@sendible/design-system/src';
import sharedStateBridge from '@sendible/shared-state-bridge';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationTypeEnum } from '@sendible/design-system/src/components/molecules/Toast';
import { Heading, InputContainer, PopoverComponent, PopoverContent, PopoverIcon } from './index.styles';
import { useUrlWithCredentials } from '../../data-layer/useUrlWithCredentials';
import { ProfilesUrls } from '../../constants/urls';

const BlueskyConnection = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { showToastNotification } = useToastNotificationContext();

  const getUrlWithCredentials = useUrlWithCredentials();
  const { t } = useTranslation('bluesky');

  const showErrorMessage = () => {
    const notificationText = `${t('cant_connect_your_account')} (${username}). ${t('try_again')}.`;

    showToastNotification({
      hideTimeout: 10,
      notificationText,
      type: NotificationTypeEnum.Error,
      showCloseButton: true,
    });
  };

  const showSuccessMessage = () => {
    const notificationText = `${t('account_connected')} (${username}) ${t('has_been_connected')}.`;

    showToastNotification({
      hideTimeout: 10,
      notificationText,
      type: NotificationTypeEnum.Success,
      showCloseButton: true,
    });
  };

  const handleConnect = async () => {
    const sanitisedUsername = username.replace(/@/g, '');

    setIsLoading(true);

    try {
      const url = `${getUrlWithCredentials(ProfilesUrls.blueskyAuthenticate)}&username=${sanitisedUsername}&password=${password}`;

      const { result } = await fetchWithHeaders({
        method: 'POST',
        url,
        headers: { 'Content-Type': 'application/json' },
      });

      if (result?.status && result.status === 'Succeeded') {
        showSuccessMessage();
        sharedStateBridge.updateSpecificKey('app', 'isBlueskyModalOpen', false);
      } else {
        showErrorMessage();
      }
    } catch (err) {
      showErrorMessage();
    } finally {
      setIsLoading(false);
    }
  };

  const isFormValid = username.trim() !== '' && password.trim() !== '' && password.length > 18;

  return (
    <Modal
      variation="medium"
      headingTitle={
        <Heading>
          <span className="icon icon-bluesky" /> {t('bluesky_connect') as string}
        </Heading>
      }
      close={() => {
        sharedStateBridge.updateSpecificKey('app', 'isBlueskyModalOpen', false);
      }}
    >
      <Box
        orientation="column"
        alignSelf="flex-start"
        fullWidth
      >
        <Text>{t('bluesky_username')}</Text>
        <InputContainer>
          <Input
            ariaLabel={t('bluesky_username') as string}
            change={(value) => setUsername(value)}
            label={t('bluesky_username_placeholder') as string}
            value={username}
          />
          <PopoverComponent
            useClick
            placement="right-start"
            maxWidth="15rem"
            content={
              <PopoverContent>
                <h6>{t('where_is_this')}</h6>
                <ol>
                  <li>
                    <span>
                      {t('open_bluesky_app')}{' '}
                      <a
                        href="https://bsky.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('bluesky_app')}
                      </a>{' '}
                      {t('and_login')}
                    </span>
                    {t('navigate_to_profile')}.
                  </li>
                  <li>
                    <span>{t('find_username')}</span>
                    {t('tap_profile_picture_instruction')}
                  </li>
                </ol>
              </PopoverContent>
            }
          >
            <PopoverIcon
              name="help"
              color="brandDark"
            />
          </PopoverComponent>
        </InputContainer>
        <Text>{t('enter_bluesky_password')}</Text>
        <InputContainer>
          <Input
            ariaLabel={t('bluesky_password') as string}
            className="bluesky-input"
            change={(value) => {
              const cleaned = value.replace(/[^a-zA-Z0-9]/g, '');
              const limited = cleaned.slice(0, 16);
              const formatted = limited.replace(/(.{4})/g, '$1-');

              setPassword(formatted.replace(/-$/, ''));
            }}
            maxLength={19}
            label="____-____-____-____"
            value={password}
          />
          <PopoverComponent
            useClick
            placement="right-start"
            maxWidth="18rem"
            content={
              <PopoverContent>
                <ol>
                  <li>
                    <span>
                      {t('go_to')}{' '}
                      <a
                        href="https://bsky.app/settings/app-passwords"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('bluesky_settings_path')}
                      </a>
                    </span>
                  </li>
                  <li>
                    <span>{t('generate_new_password')}</span>
                    {t('add_app_password_instruction')}
                  </li>
                  <li>
                    <span>{t('paste_in_field')}</span>
                    {t('use_generated_password')}
                  </li>
                </ol>
                <hr />
                <p>
                  {t('app_passwords_management')}{' '}
                  <a
                    href="https://bsky.app/settings/app-passwords"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('bluesky_settings')}
                  </a>
                  .
                </p>
              </PopoverContent>
            }
          >
            <PopoverIcon
              name="help"
              color="brandDark"
            />
          </PopoverComponent>
        </InputContainer>
        <Box
          contentJustify="space-between"
          alignSelf="center"
          contentAlignment="center"
          fullWidth
        >
          <Text>{t('pds_not_supported')}</Text>
          <Button
            appearance="primary"
            disabled={!isFormValid || isLoading}
            isLoading={isLoading}
            label={t('connect') as string}
            onClick={handleConnect}
            size={14}
            variation="fill"
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default BlueskyConnection;
