import SharedStateBridge, { useBridgeContext } from '@sendible/shared-state-bridge';
import { Box, Button, Icon } from '@sendible/design-system';
import { useTranslation } from 'react-i18next';
import { ModalAddProfile } from './index.styles';
import { useServiceConnection } from '../../hooks/useServiceConnection';

interface AddProfileModalPromptProps {
  serviceType: 'threads' | 'tiktok';
  mode: 'add' | 'reconnect';
}

export const AddProfileModalPrompt = ({ serviceType, mode }: AddProfileModalPromptProps) => {
  const [sharedState] = useBridgeContext();
  const { connectToService } = useServiceConnection();
  const { t } = useTranslation('common_terms');

  const serviceName = serviceType === 'threads' ? 'Threads' : 'TikTok';
  const serviceIcon = serviceType === 'threads' ? 'icon-threads-sign' : 'icon-tiktok';
  const serviceLoginUrl = serviceType === 'threads' ? 'https://www.threads.net/' : 'https://www.tiktok.com/';
  const promptStateKey = serviceType === 'threads' ? 'addThreadsPromptOpenMode' : 'addTikTokPromptOpenMode';
  const headingTextTkey = mode === 'add' ? 'connect_service' : 'reconnect_service';

  return (
    <ModalAddProfile
      testId="profile-add-prompt"
      headingTitle={
        <>
          <span className={`icon ${serviceIcon}`} /> {t(headingTextTkey, { service: serviceName })}
        </>
      }
      footerContent={
        <>
          <Icon name="info" />
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('connect_different_account', {
                service: serviceName,
                service_login: serviceLoginUrl,
              }),
            }}
          />
        </>
      }
      variation="medium"
      close={() => {
        SharedStateBridge.updateSpecificKey('app', promptStateKey, null);
        SharedStateBridge.updateSpecificKey('app', 'addingServiceId', null);
      }}
    >
      <Box
        orientation="column"
        overflow="nowrap"
        contentAlignment="center"
        verticalGap="s4"
        padding={['s32', 's24', 's32', 's24']}
      >
        <p>{t('link_by_continuing', { service: serviceName })}</p>
        <Button
          appearance="primary"
          label={t('continue_to', { service: serviceName }) as string}
          onClick={() => {
            const addingServiceId = sharedState?.app?.addingServiceId;

            if (addingServiceId) {
              connectToService(addingServiceId);
            }
            SharedStateBridge.updateSpecificKey('app', 'addingServiceId', null);
            SharedStateBridge.updateSpecificKey('app', promptStateKey, null);
          }}
          secondIcon="arrow_up_right"
          size={14}
          variation="fill"
        />
      </Box>
    </ModalAddProfile>
  );
};
