import sharedStateBridge, { composeTabs, composeTabsInfo, COMPOSE_TABS, MediaData } from '@sendible/shared-state-bridge';
import { publish, EVENTS } from '@sendible/event-manager';

const clearComposeBoxState = () => {
  sharedStateBridge.updateSpecificKey('compose', 'isComposeBoxOpen', false);
  sharedStateBridge.updateSpecificKey('compose', 'attachedMedias', undefined);
  sharedStateBridge.updateSpecificKey('compose', 'mediaDataMap', undefined);
  sharedStateBridge.updateSpecificKey('compose', 'activeComposeBoxTab', undefined);
};

const setActiveComposeBoxTab = (tabId: composeTabs | undefined) => sharedStateBridge.updateSpecificKey('compose', 'activeComposeBoxTab', tabId);

const setIsComposeBoxOpen = (state: boolean) => sharedStateBridge.updateSpecificKey('compose', 'isComposeBoxOpen', state);

const setIsMediaAttacherOpen = (state: boolean) => sharedStateBridge.updateSpecificKey('compose', 'isMediaAttacherOpen', state);

const toggleMediaAttacher = () => {
  const currentMediaAttacherState = sharedStateBridge.getSharedState().compose.isMediaAttacherOpen || false;

  sharedStateBridge.updateSpecificKey('compose', 'isMediaAttacherOpen', !currentMediaAttacherState);
};

const attachMessageTabMediasToOtherTabs = () => {
  const sharedState = sharedStateBridge.getSharedState()?.compose;
  const { attachedMedias, presentComposeBoxTabsInfo } = sharedState;

  if (!attachedMedias || !attachedMedias[COMPOSE_TABS.MESSAGE]) return;

  const mediaInfo = attachedMedias;

  Object.keys(presentComposeBoxTabsInfo).forEach((tabName) => {
    if (presentComposeBoxTabsInfo[tabName].isCustomised === false) {
      mediaInfo[tabName] = Array.from(new Set([...mediaInfo[COMPOSE_TABS.MESSAGE]]));
    }
  });
  sharedStateBridge.updateSpecificKey('compose', 'attachedMedias', mediaInfo);
};

const addToMediaDataMap = (mediaData: MediaData) => {
  const sharedState = sharedStateBridge.getSharedState()?.compose;

  if (!sharedState) throw new Error('app not initialised in shared state');

  const { mediaDataMap = {} } = sharedState;

  const mediaKey = `mediaId_${mediaData.id}`;

  const updatedMediaMap = {
    ...mediaDataMap,
    [mediaKey]: mediaData,
  };

  sharedStateBridge.updateSpecificKey('compose', 'mediaDataMap', updatedMediaMap);
};

const getAttachedMediaForActiveTab = () => {
  const sharedState = sharedStateBridge.getSharedState()?.compose;

  if (!sharedState) throw new Error('app not initialised in shared state');

  const { activeComposeBoxTab, attachedMedias } = sharedState;

  if (!attachedMedias || !attachedMedias[activeComposeBoxTab]) {
    return [];
  }

  return attachedMedias[activeComposeBoxTab];
};

const getNumberOfAttachedMedia = () => {
  return getAttachedMediaForActiveTab().length;
};

const hasAttachedTypeOf = (type: string) => {
  const sharedState = sharedStateBridge.getSharedState()?.compose;

  if (!sharedState) throw new Error('app not initialised in shared state');

  const { activeComposeBoxTab, attachedMedias, mediaDataMap = {} } = sharedState;

  if (!attachedMedias || !attachedMedias[activeComposeBoxTab]) {
    return false;
  }

  const activeTabMediaIds = attachedMedias[activeComposeBoxTab];

  if (!activeTabMediaIds.length) {
    return false;
  }

  return activeTabMediaIds.some((mediaId: number) => {
    const mediaKey = `mediaId_${mediaId}`;
    const mediaItem = mediaDataMap[mediaKey];

    return mediaItem?.type === type;
  });
};

const attachMediaToActiveTab = (mediaId: number, mediaToAttach: File) => {
  const sharedState = sharedStateBridge.getSharedState()?.compose;

  if (!sharedState) throw new Error('app not initialised in shared state');

  setIsMediaAttacherOpen(false);

  const { activeComposeBoxTab, attachedMedias } = sharedState;
  const mediaInfo = {
    ...attachedMedias,
    [activeComposeBoxTab]: Array.from(
      new Set([...(attachedMedias && attachedMedias[activeComposeBoxTab] ? attachedMedias[activeComposeBoxTab] : []), mediaId])
    ),
  };

  sharedStateBridge.updateSpecificKey('compose', 'attachedMedias', mediaInfo);

  if (activeComposeBoxTab === COMPOSE_TABS.MESSAGE) attachMessageTabMediasToOtherTabs();

  addToMediaDataMap({ id: mediaId, type: mediaToAttach.type });
};

const setMajorErrorFeedback = (message: string) => {
  publish(EVENTS.ERROR_MESSAGE_FEEDBACK, {
    message,
  });
};

const hasNewMedia = () => {
  return !!sharedStateBridge.getSharedState()?.compose?.attachedMedias;
};

const setPresentComposeBoxTabsInfo = (tabInfo: composeTabsInfo) => {
  sharedStateBridge.updateSpecificKey('compose', 'presentComposeBoxTabsInfo', tabInfo);
};

const removeMediaFromActiveTab = (mediaIdToRemove: number) => {
  const sharedState = sharedStateBridge.getSharedState()?.compose;

  if (!sharedState) throw new Error('app not initialised in shared state');

  const { activeComposeBoxTab, attachedMedias } = sharedState;
  const mediaInfo = {
    ...attachedMedias,
    [activeComposeBoxTab]: attachedMedias[activeComposeBoxTab].filter((id: number) => id !== mediaIdToRemove),
  };

  sharedStateBridge.updateSpecificKey('compose', 'attachedMedias', mediaInfo);

  if (activeComposeBoxTab === COMPOSE_TABS.MESSAGE) attachMessageTabMediasToOtherTabs();
};

const removeAllMediaFromActiveTab = () => {
  const sharedState = sharedStateBridge.getSharedState()?.compose;
  const { activeComposeBoxTab, attachedMedias } = sharedState;

  if (attachedMedias && attachedMedias[activeComposeBoxTab]) {
    attachedMedias[activeComposeBoxTab].forEach((mediaId: number) => {
      removeMediaFromActiveTab(mediaId);
    });
  }
};

export const composeMediaInteractions = {
  setActiveComposeBoxTab,
  setIsComposeBoxOpen,
  setIsMediaAttacherOpen,
  setPresentComposeBoxTabsInfo,
  toggleMediaAttacher,
  clearComposeBoxState,
  hasNewMedia,
  attachMediaToActiveTab,
  hasAttachedTypeOf,
  setMajorErrorFeedback,
  removeMediaFromActiveTab,
  attachMessageTabMediasToOtherTabs,
  removeAllMediaFromActiveTab,
  getNumberOfAttachedMedia,
  getAttachedMediaForActiveTab,
};
