/* eslint-disable no-nested-ternary */
import { useRef, useMemo } from 'react';
import { Icon, MEDIUM_MAX_SIZE, Spinner, Text } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { useGetTikTokAudience } from '@sendible/frontendv2/src/models/reports';
import {
  Container,
  LargeStat,
  StatRow,
  StatsContainer,
  StatsTable,
  TopMetrics,
  LargeStatContainer,
  TrendContainer,
  ChartContainer,
  SectionContainer,
  LoadingWrapper,
} from './index.styles';
import { Chart } from './components/Chart';
import { chartDummyData } from './mocks';
import { useReportsContext } from '../../../context';
import { useContainerDimensions, useFormattedDates } from '../../../hooks';
import { Warning } from '../Warning';
import { useTikTokReportContext } from '../../context';
import { TrendArrow } from '../../index.styles';

export const Audience = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isPrint } = useReportsContext();
  const { isSmallScreen } = useContainerDimensions(containerRef, MEDIUM_MAX_SIZE, 0.6, 0.625);
  const { t } = useTranslation('reports');
  const { previousEndDate, previousStartDate } = useTikTokReportContext();
  const { isLoading, data, isError, isRefetching } = useGetTikTokAudience();

  const { startDateFriendly, endDateFriendly } = useFormattedDates({ startDate: previousStartDate, endDate: previousEndDate });

  const isGettingData = isLoading || isRefetching;

  const translations = useMemo(
    () => ({
      metricsText: t('metrics'),
      profileViewsText: t('profile_views'),
      netFollowersText: t('net_followers'),
      followersText: t('followers'),
      netFollowersGrowthText: t('net_followers_growth'),
      comparedToPreviousPeriodText: t('compared_to_previous_period'),
      nothing_here_yet: t('nothing_here_yet'),
    }),
    [t]
  );

  if (isGettingData) {
    return (
      <LoadingWrapper>
        <Spinner size="lg" />
      </LoadingWrapper>
    );
  }

  const noData = !data?.audience;

  const {
    audience: {
      timeSeriesData,
      totals: { profileViews, followers, netFollowers },
    },
  } = noData ? chartDummyData : data;

  const showWarning = noData || !followers || !followers.current;
  const netFollowersIsPositive = netFollowers?.difference && netFollowers.difference > 0;

  return (
    <Container>
      {isError ? (
        <Warning
          text={t('generic_report_error')}
          type="danger"
          color="semanticDangerShade20"
        />
      ) : showWarning ? (
        <Warning
          text={translations.nothing_here_yet}
          type="info"
          color="semanticInfo"
        />
      ) : null}
      <SectionContainer
        $isHidden={showWarning}
        horizontalGap="s8"
        orientation="row"
        $isPrint={isPrint}
      >
        <ChartContainer $isPrint={isPrint}>
          <Chart
            timeSeriesData={timeSeriesData}
            isSmallScreen={isSmallScreen}
          />
        </ChartContainer>
        {!showWarning && (
          <StatsContainer
            orientation="column"
            contentJustify="space-between"
            $isPrint={isPrint}
          >
            <TopMetrics
              orientation="column"
              $isPrint={isPrint}
            >
              <h3>{translations.metricsText}</h3>
              <StatsTable $isPrint={isPrint}>
                <StatRow contentJustify="space-between">
                  <div>{translations.profileViewsText}</div>
                  <div>{profileViews.current}</div>
                </StatRow>
                <StatRow contentJustify="space-between">
                  <div>{translations.followersText}</div>
                  <div>{followers.current}</div>
                </StatRow>
                <StatRow contentJustify="space-between">
                  <div>{translations.netFollowersText}</div>
                  <div>{netFollowers?.current ? netFollowers.current : '-'}</div>
                </StatRow>
              </StatsTable>
            </TopMetrics>
            <LargeStatContainer
              orientation="column"
              alignSelf="flex-end"
              contentAlignment="flex-end"
              $isPrint={isPrint}
            >
              <h3>{translations.netFollowersGrowthText}</h3>
              <LargeStat>
                {netFollowersIsPositive && <>+</>}
                {netFollowers?.difference ? netFollowers.difference : '-'}
              </LargeStat>
              {netFollowers?.difference && (
                <TrendContainer>
                  <TrendArrow $isPositive={!!netFollowersIsPositive}>
                    <Icon
                      name={netFollowersIsPositive ? 'arrow_up_right' : 'arrow_down_left'}
                      color="brandLight"
                    />
                  </TrendArrow>
                  <Text variation="body_16">
                    {netFollowers.difference} {translations.comparedToPreviousPeriodText}
                  </Text>
                </TrendContainer>
              )}
              <Text
                variation="body_16"
                color="brandDarkTint30"
              >
                vs {startDateFriendly} - {endDateFriendly}
              </Text>
            </LargeStatContainer>
          </StatsContainer>
        )}
      </SectionContainer>
    </Container>
  );
};
