import { fetchWithHeaders, throwError, urls } from '@sendible/common';
import { MutationFunction, QueryFunction, QueryFunctionContext, useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { mediasLoadedInfiniteScroll } from '../pages/MediaLibrary/pendoEvents';

export function useQueryWithAccessToken<TData>(
  [endpoint, params]: DLQueryKey,
  options?: UseQueryOptionsWithGenerics<TData>,
  useErrorBoundary = true
) {
  const [{ user }] = useBridgeContext();
  let credentials: object = { access_token: user.accessToken };

  if (user.isSwitchedUser) {
    credentials = { username: user.username, logged_in_access_token: user.accessToken };
  }

  const newQueryKey: DLQueryKey = [endpoint, { ...credentials, ...(params as object) }];
  const queryFn: QueryFunction<DLAPIResponseType, DLQueryKey> = async ({ queryKey }: QueryFunctionContext<DLQueryKey>) => {
    const [endpnt, prms] = queryKey;

    return fetchWithHeaders({ method: 'GET', url: `${urls.baseUrl}${endpnt}`, params: prms })
      .then((response) => {
        return response.result ? response.result : response;
      })
      .catch((error) => {
        window.dispatchEvent(
          new CustomEvent('reportErrorToFaro', { detail: { error, data: { method: 'GET', url: `${urls.baseUrl}${endpnt}`, params: prms } } })
        );

        return throwError(error);
      });
  };

  return useQuery(newQueryKey, queryFn, { useErrorBoundary, ...options });
}
export function useInfiniteQueryWithAccessToken<TData>([endpoint, params]: DLInfiniteQueryKey, options?: UseInfiniteQueryOptionsWithGenerics<TData>) {
  const [
    {
      user: { accessToken },
    },
  ] = useBridgeContext();

  const newQueryKey: DLInfiniteQueryKey = [endpoint, { access_token: accessToken, ...params }];
  const queryFn: QueryFunction<DLAPIResponseType, DLQueryKey> = async ({ queryKey, pageParam = 1 }) => {
    const [endpnt, prms] = queryKey;

    const newParams = {
      ...prms,
      page: pageParam,
    };

    window.pendo.track(mediasLoadedInfiniteScroll, {
      perPage: params.perPage,
      page: pageParam,
    });

    return fetchWithHeaders({ method: 'GET', url: `${urls.baseUrl}${endpnt}`, params: newParams }).then(({ result }) => result);
  };

  return useInfiniteQuery(newQueryKey, queryFn, {
    ...options,
    useErrorBoundary: true,
    getNextPageParam: (lastPage, allPages) => (lastPage && lastPage.length < params.perPage ? undefined : allPages.length + 1),
  });
}

export function useMutationWithAccessToken<TData, TVariables>({
  method,
  endpoint,
  options,
  body = {},
  mutationFn,
}: UseMutationWithAccessTokenParamsType<TData, TVariables>) {
  const [
    {
      user: { accessToken },
    },
  ] = useBridgeContext();

  const defaultMutationFn: MutationFunction<TData, object> = async (variables: object) => {
    const newContent = { access_token: accessToken, ...variables };

    if (mutationFn) {
      return mutationFn(newContent as TVariables);
    }

    return fetchWithHeaders({ method, url: `${urls.baseUrl}${endpoint}`, params: newContent, body }).then(({ result }) => result);
  };

  return useMutation(defaultMutationFn, { ...options });
}
