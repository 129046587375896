/* eslint-disable no-nested-ternary */
import { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@sendible/design-system/src';
import { useGetTikTokPublishing } from '@sendible/frontendv2/src/models/reports';
import { Chart } from './components/Chart';
import { Legend } from './components/Legend';
import {
  Container,
  ChartContainer,
  StatsContainer,
  TopMetrics,
  StatsTable,
  StatRow,
  ChartHeader,
  Wrapper,
  ChartLegendContainer,
  LoadingWrapper,
} from '../index.styles';
import { chartDummyData } from '../mocks';
import { Warning } from '../..';

export const PublishingBehaviour = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(['reports', 'common_terms']);
  const { data, isLoading, isError, isRefetching } = useGetTikTokPublishing();

  const isGettingData = isLoading || isRefetching;

  const translations = useMemo(
    () => ({
      publishingBehaviour: t('publishing_behaviour'),
      summary: t('summary'),
      totalPublishedVideos: t('total_published_videos'),
      mostFrequentDay: t('most_frequent_day'),
      mostFrequentTime: t('most_frequent_time'),
      nothingHereYet: t('nothing_here_yet'),
    }),
    [t]
  );

  const noData = !!(!data?.publishingBehaviour || !data?.publishingBehaviour?.posts.length);

  const {
    publishingBehaviour: { posts, summary },
  } = noData ? chartDummyData : data;

  if (isGettingData) {
    return (
      <LoadingWrapper>
        <Spinner size="lg" />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      {isError ? (
        <Warning
          text={t('generic_report_error')}
          type="danger"
          color="semanticDangerShade20"
        />
      ) : noData ? (
        <Warning
          text={translations.nothingHereYet}
          type="info"
          color="semanticInfo"
        />
      ) : null}
      <Container
        ref={containerRef}
        $isHidden={noData}
      >
        <ChartHeader>{translations.publishingBehaviour}</ChartHeader>
        <ChartLegendContainer>
          <ChartContainer>
            <Chart posts={posts} />
          </ChartContainer>
          <Legend />
        </ChartLegendContainer>
        {!noData && (
          <StatsContainer
            orientation="column"
            contentJustify="space-between"
          >
            <TopMetrics orientation="column">
              <h3>{translations.summary}</h3>
              <StatsTable>
                <StatRow contentJustify="space-between">
                  <div>{translations.totalPublishedVideos}</div>
                  <div>{summary?.totalPosts || '-'}</div>
                </StatRow>
                <StatRow contentJustify="space-between">
                  <div>{translations.mostFrequentDay}</div>
                  <div>{summary?.mostFrequentDay ? t(summary.mostFrequentDay.toLowerCase(), { ns: 'common_terms' }) : '-'}</div>
                </StatRow>
                <StatRow contentJustify="space-between">
                  <div>{translations.mostFrequentTime}</div>
                  <div>{summary?.mostFrequentTime || '-'}</div>
                </StatRow>
              </StatsTable>
            </TopMetrics>
          </StatsContainer>
        )}
      </Container>
    </Wrapper>
  );
};
