import { useEffect, useState } from 'react';
import { fetchWithHeaders } from '@sendible/common';
import Editor from './editor';

export const EDITOR_EXPORT = 'creative-editor-export';
export const EDITOR_CLOSE = 'creative-editor-export-close';

const PhotoEditor = () => {
  const [preloadEditor, setPreloadEditor] = useState<boolean>(false);

  const [data, setData] = useState<OpenEditorDataType>({} as OpenEditorDataType);

  useEffect(() => {
    const getReducedImage = async (imgTag: HTMLImageElement, reduction: number): Promise<{ uri: string; width: number; height: number }> => {
      // Create canvas for scaling
      const canvas = document.createElement('canvas');

      canvas.width = imgTag.naturalWidth / reduction;
      canvas.height = imgTag.naturalHeight / reduction;

      // paint scaled image in canvas
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(imgTag, 0, 0, canvas.width, canvas.height);
      }

      // to blob is async so we need to wait for the promise to resolve
      return new Promise((res, rej) => {
        canvas.toBlob((blob) => {
          if (blob) {
            const blobUrl = URL.createObjectURL(blob);

            res({
              height: canvas.height,
              width: canvas.width,
              uri: blobUrl,
            });
          }
          rej();
        }, 'image/jpeg');
      });
    };
    const handleMessage = (event: MessageEvent) => {
      const { eventName, data: eventData } = event.data;

      if (eventName === 'preload-editor' && !preloadEditor) {
        setPreloadEditor(true);
      }

      if (eventName === 'open-editor') {
        window.pendo.initialize({
          visitor: eventData.pendoUserData,
          account: eventData.pendoAccountData,
        });

        const loginContext = eventData.loginContext.split('&').reduce((acc: Record<string, string>, pair: string) => {
          const [key, value] = pair.split('=');

          if (key) {
            acc[key] = decodeURIComponent(value || '');
          }

          return acc;
        }, {});

        // I'm very sorry about you having to go through this piece of code.
        // This is here because in the compose box we are loading hidden image tags
        // that do not pass CORS headers, so they pass a no-cors header and then what you have is an opaque response
        // that can't be loaded into a canvas.
        // In order to solve this, we first need to load another hidden image tag but this time with the CORS headers
        const imageTag = new Image();

        imageTag.crossOrigin = 'anonymous';
        imageTag.onload = async () => {
          // if the request works and the image loads, we then open the editor
          // but before opening the editor, we need to resize the image to pass different sizes to it,
          // as the editor doesn't perform good with large images, they provide the option of using "source-sets"
          const quarterSizeImage = await getReducedImage(imageTag, 4);
          const halfSizeImage = await getReducedImage(imageTag, 2);
          const originalImage = {
            height: imageTag.height,
            width: imageTag.width,
            uri: imageTag.src,
          };

          // when data is set, the editor opens
          setData({
            originalImage,
            quarterSizeImage,
            halfSizeImage,
            serviceSet: eventData.serviceSet,
            restrictedServices: eventData.restrictedServices,
          });
        };
        // but as we are able to have third party urls, even calling with CORS headers can fail, also,
        // in some cases, the browser will still retrieve this new request from cache, which would not have the cors headers,
        // and in that case, we need to call an API edpoint that will give us the base64 string
        imageTag.onerror = async () => {
          const response = await fetchWithHeaders({
            method: 'GET',
            url: `https://${WEBPACK_API}/api/v2/content_proxy.json`,
            params: {
              ...loginContext,
              url: eventData.imageUrl,
              isBase64: 'true',
            },
          });

          if (response.data) {
            // if the endpoint returns the base64 string successfully,
            // then we convert it to a blob url, as the editor doesn't accept base64 as urls.
            const byteString = atob(response.data.split(',')[1]); // we need to remove the 'data:image/jpeg;base64,' prefix
            const arrayBuffer = new Uint8Array(byteString.length);

            for (let i = 0; i < byteString.length; i++) {
              arrayBuffer[i] = byteString.charCodeAt(i);
            }

            const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
            const blobURL = URL.createObjectURL(blob);

            imageTag.src = blobURL;
          }
        };
        imageTag.src = eventData.imageUrl;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // do not load the Editor component until the preload flag is set
  // this ensure any performance hit from initialising CESDK
  // is delayed unit the feature is ready to use
  if (!preloadEditor || !data.originalImage) {
    return null;
  }

  return (
    <Editor
      originalImage={data.originalImage}
      halfSizeImage={data.halfSizeImage}
      quarterSizeImage={data.quarterSizeImage}
      serviceSet={data.serviceSet}
      restrictedServices={data.restrictedServices}
      resetState={() => {
        setData({} as OpenEditorDataType);
      }}
    />
  );
};

export default PhotoEditor;
