import { Box } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const HeaderContainer = styled(Box)`
  ${({ theme: { colors } }) => css`
    background: ${colors.brandLight};
    box-shadow: 0px 1px 0px 0px #c7c6ce;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 3;
  `}
`;

export const Group = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;
