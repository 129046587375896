/* eslint-disable no-nested-ternary */
import { useRef, useMemo } from 'react';
import { Spinner } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { capitalizeFirst } from '@sendible/common';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useGetTikTokPublishing } from '@sendible/frontendv2/src/models/reports';
import { internationalizeNumberHourOfDay } from '@sendible/frontendv2/src/utils';
import { Chart } from './components/Chart';
import { chartDummyData } from '../mocks';
import {
  Container,
  StatsContainer,
  TopMetrics,
  StatsTable,
  StatRow,
  ChartHeader,
  Wrapper,
  ChartLegendContainer,
  LoadingWrapper,
} from '../index.styles';
import { Warning } from '../..';

export const FollowersOnline = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(['reports', 'common_terms']);
  const { data, isLoading, isError, isRefetching } = useGetTikTokPublishing();
  const [
    {
      user: { language },
    },
  ] = useBridgeContext();
  const isGettingData = isLoading || isRefetching;

  const translations = useMemo(
    () => ({
      followersOnline: t('followers_online'),
      summary: t('summary'),
      medianHour: t('median_followers_online_hour'),
      medianDay: t('median_followers_online_day'),
      needsMoreData: t('followers_online_no_data'),
    }),
    [t]
  );

  const noData = !!(!data?.followersOnline || !data?.followersOnline?.followersActivity.length);

  const {
    followersOnline: { followersActivity, summary },
  } = noData ? chartDummyData : data;

  const medianFollowersDay = summary?.dayWithMaxMedianFollowers ? t(summary.dayWithMaxMedianFollowers.toLowerCase(), { ns: 'common_terms' }) : '-';

  if (isGettingData) {
    return (
      <LoadingWrapper>
        <Spinner size="lg" />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      {isError ? (
        <Warning
          text={t('generic_report_error')}
          type="danger"
          color="semanticDangerShade20"
        />
      ) : noData ? (
        <Warning
          text={translations.needsMoreData}
          type="info"
          color="semanticInfo"
        />
      ) : null}
      <Container
        ref={containerRef}
        $isHidden={noData}
      >
        <ChartHeader>{translations.followersOnline}</ChartHeader>
        <ChartLegendContainer>
          <Chart followersActivity={followersActivity} />
        </ChartLegendContainer>
        {!noData && (
          <StatsContainer
            orientation="column"
            contentJustify="space-between"
          >
            <TopMetrics orientation="column">
              <h3>{translations.summary}</h3>
              <StatsTable margin={['s0', 's0', 's16', 's0']}>
                <StatRow contentJustify="space-between">
                  <div>{translations.medianHour}</div>
                  <div>
                    {summary?.hourWithMaxMedianFollowers ? internationalizeNumberHourOfDay(summary.hourWithMaxMedianFollowers, language) : '-'}
                  </div>
                </StatRow>
                <StatRow contentJustify="space-between">
                  <div>{translations.medianDay}</div>
                  <div>{capitalizeFirst(medianFollowersDay)}</div>
                </StatRow>
              </StatsTable>
            </TopMetrics>
          </StatsContainer>
        )}
      </Container>
    </Wrapper>
  );
};
