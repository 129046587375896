export const chartDummyData = {
  audienceGenders: [
    {
      gender: 'Female',
      percentage: 46.4,
      id: 'Female',
      value: 46.4,
    },
    {
      gender: 'Male',
      percentage: 52.67,
      id: 'Male',
      value: 52.67,
    },
    {
      gender: 'Not Specified',
      percentage: 0.93,
      id: 'Not Specified',
      value: 0.93,
    },
  ],
  audienceCountries: [
    {
      country: 'GB',
      percentage: 69.95,
      name: 'United Kingdom',
      countryCode: 'GB',
      className: 'first',
    },
    {
      country: 'IE',
      percentage: 20.45,
      name: 'Ireland',
      countryCode: 'IE',
      className: 'top-three',
    },
    {
      country: 'US',
      percentage: 4.86,
      name: 'United States',
      countryCode: 'US',
      className: 'top-three',
    },
    {
      country: 'AU',
      percentage: 1.37,
      name: 'Australia',
      countryCode: 'AU',
      className: '',
    },
    {
      country: 'ZA',
      percentage: 0.75,
      name: 'South Africa',
      countryCode: 'ZA',
      className: '',
    },
    {
      country: 'PK',
      percentage: 0.62,
      name: 'Pakistan',
      countryCode: 'PK',
      className: '',
    },
    {
      country: 'CA',
      percentage: 0.5,
      name: 'Canada',
      countryCode: 'CA',
      className: '',
    },
    {
      country: 'DE',
      percentage: 0.5,
      name: 'Germany',
      countryCode: 'DE',
      className: '',
    },
    {
      country: 'IT',
      percentage: 0.5,
      name: 'Italy',
      countryCode: 'IT',
      className: '',
    },
    {
      country: 'NL',
      percentage: 0.5,
      name: 'Netherlands',
      countryCode: 'NL',
      className: '',
    },
  ],
} as UseGetTikTokDemographicsReturnType;
