export const emptyApiData = {
  metrics: [
    {
      key: 0,
      label: 'Profile Views',
      current: '-',
      isPositive: true,
      difference: '-',
      percentage: '-',
      previous: '-',
      tooltipText: '',
    },
    {
      key: 1,
      label: 'Followers',
      current: '-',
      isPositive: true,
      difference: '-',
      percentage: '-',
      previous: '-',
      tooltipText: '',
    },
    {
      key: 2,
      label: 'Video Views',
      current: '-',
      isPositive: true,
      difference: '-',
      percentage: '-',
      previous: '-',
      tooltipText: '',
    },
    {
      key: 3,
      label: 'Interactions',
      current: '-',
      isPositive: true,
      difference: '-',
      percentage: '-',
      previous: '-',
      tooltipText: '',
    },
  ],
  profileImage: '',
};
