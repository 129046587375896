/* eslint-disable react/jsx-no-useless-fragment */
import { DragEvent, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Heading, Icon, Text } from '@sendible/design-system';
import { useTranslation } from 'react-i18next';
import { Container, Content, DashedCircle, FileTypeRow, IconsRow, SupportedFilesRow } from './index.styles';
import { useComposeBoxContext } from '../../composeBoxContext';
import { composeMediaInteractions } from '../../composeMediaInteractions';

export const DragAndDrop = () => {
  const [showComponent, setShowComponent] = useState(false);
  const { handleFileUpload } = useComposeBoxContext();
  const { t } = useTranslation('common_terms');

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { files } = event.dataTransfer;

    setShowComponent(false);
    handleFileUpload(Array.from(files), 'drag_and_drop');
  };

  const preventDragDefault = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    composeMediaInteractions.setIsMediaAttacherOpen(false);
  };

  useEffect(() => {
    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
      if (e.dataTransfer?.types.every((type) => type === 'Files')) {
        setShowComponent(true);
      }
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
      if (!e.clientX && !e.clientY) {
        setShowComponent(false);
      }
    };

    document.addEventListener('dragover', (e: unknown) => handleDragOver(e as DragEvent<HTMLDivElement>));
    document.addEventListener('dragleave', (e: unknown) => handleDragLeave(e as DragEvent<HTMLDivElement>));

    return () => {
      document.removeEventListener('dragover', (e: unknown) => handleDragOver(e as DragEvent<HTMLDivElement>));
      document.removeEventListener('dragleave', (e: unknown) => handleDragLeave(e as DragEvent<HTMLDivElement>));
    };
  }, []);

  return (
    <>
      {showComponent &&
        createPortal(
          <Container
            onDrop={(event) => handleDrop(event)}
            onDragOver={(event) => preventDragDefault(event)}
            onDragEnter={(event) => preventDragDefault(event)}
          >
            <DashedCircle>
              <Content>
                <IconsRow>
                  <Icon
                    name="image"
                    color="brandLight"
                    strokeWidth={1.5}
                  />
                  <Icon
                    name="file"
                    color="brandLight"
                    strokeWidth={1.5}
                  />
                  <Icon
                    name="video"
                    color="brandLight"
                    strokeWidth={1.5}
                  />
                </IconsRow>
                <Heading
                  semantic="h2"
                  variation="layout-title_32"
                  color="brandLight"
                >
                  {t('drop_files')}
                </Heading>
                <SupportedFilesRow>
                  <Heading
                    semantic="h6"
                    variation="list-title_12"
                    color="brandLight"
                  >
                    {t('supported_files')}
                  </Heading>
                  <FileTypeRow>
                    <Text>{t('images')}</Text>
                    <Text>PNG, JPG, GIF</Text>
                  </FileTypeRow>
                  <FileTypeRow>
                    <Text>{t('videos')}</Text>
                    <Text>MP4, MOV</Text>
                  </FileTypeRow>
                  <FileTypeRow>
                    <Text>{t('documents')}</Text>
                    <Text>{t('pdf_linkedin')}</Text>
                  </FileTypeRow>
                </SupportedFilesRow>
              </Content>
            </DashedCircle>
          </Container>,
          document.getElementById('modal-root') as HTMLElement
        )}
    </>
  );
};
