import { useBridgeContext } from '@sendible/shared-state-bridge';
import { DragAndDrop, MediaAttacherDropdown, MediaAttached, ConfirmReplaceMediaModal } from '../index';
import { useComposeBoxContext } from '../../composeBoxContext';

export const MediaSystemComponents = () => {
  const [sharedState] = useBridgeContext();
  const { isReplaceModalOpen } = useComposeBoxContext();

  const isComposeBoxOpen = sharedState?.compose?.isComposeBoxOpen;
  const isMediaAttacherOpen = sharedState?.compose?.isMediaAttacherOpen;
  const attachedMedias = sharedState?.compose?.attachedMedias;
  const activeComposeBoxTab = sharedState?.compose?.activeComposeBoxTab;

  return (
    <>
      {attachedMedias && activeComposeBoxTab && attachedMedias[activeComposeBoxTab] && <MediaAttached />}
      {isMediaAttacherOpen && <MediaAttacherDropdown />}
      {isReplaceModalOpen && <ConfirmReplaceMediaModal />}
      {isComposeBoxOpen && <DragAndDrop />}
    </>
  );
};
