import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    margin: 0 5rem;

    svg {
      flex-shrink: 0;
    }
  }
`;
