import { Route, Routes } from 'react-router-dom';
import { ReportsContainer } from './index.styles';
import { useReportsContext } from './context';
import { TikTokReportProvider } from './tiktok/context';
import CampaignsReport from './campaigns';
import TikTokReport from './tiktok';

export const ReportsPage = () => {
  const { isPrint } = useReportsContext();

  return (
    <ReportsContainer
      $isPrint={isPrint}
      contentJustify="space-between"
    >
      <Routes>
        <Route
          path="/campaigns/:id?"
          element={<CampaignsReport />}
        />
        <Route
          path="/tiktok/:id?"
          element={
            <TikTokReportProvider>
              <TikTokReport />
            </TikTokReportProvider>
          }
        />
      </Routes>
    </ReportsContainer>
  );
};
