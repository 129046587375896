export const faroIgnoreErrors = [
  'tinymce',
  'CanceledError: canceled',
  'AxiosError: Request failed with status code 400',
  'Error: URI malformed',
  'SyntaxError: JSON Parse error: Unexpected EOF',
  'Non-Error promise rejection captured with value: Not implemented on this platform',
  'chrome-extension://',
  '-sASSERTIONS',
  'Error: NetworkError for: https://cdn.',
];
