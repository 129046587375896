import { Box, MEDIUM_MAX_SIZE } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ $isPrint: boolean }>`
  background: white;
  padding: 2rem;
  box-sizing: border-box;
  width: 100%;
  transition: filter 0.2s, opacity 0.2s;
  gap: 0.5rem;

  @media screen and (max-width: ${MEDIUM_MAX_SIZE}px) {
    flex-direction: column;
  }
`;

export const ContainerHeader = styled(Box)``;

export const Header = styled.h2`
  font-size: 1.75rem;
`;

export const WarningContainer = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const WarningTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin: 0 5rem;

  svg {
    flex-shrink: 0;
  }
`;

export const Content = styled(Box)<{ $isPrint: boolean }>`
  ${({ $isPrint }) =>
    !$isPrint &&
    css`
      @media screen and (max-width: ${MEDIUM_MAX_SIZE}px) {
        flex-direction: column;
      }
    `}
`;

export const SectionWrapper = styled(Box)<{ $isHidden: boolean }>`
  width: 100%;
  box-sizing: border-box;

  ${({ $isHidden }) =>
    $isHidden &&
    css`
      filter: blur(4px);
      opacity: 0.15;
      pointer-events: none;
    `}
`;

export const ChartSection = styled(Box)<{ $isPrint: boolean }>`
  position: relative;
  width: calc(50% - 0.5rem);

  ${({ $isPrint }) =>
    !$isPrint &&
    css`
      @media screen and (max-width: ${MEDIUM_MAX_SIZE}px) {
        margin-bottom: 4rem;
        width: 100%;
      }
    `}
`;

export const ChartContainer = styled.div`
  height: 40vh;
  height: 40svh;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

export const StatsContainer = styled(Box)<{ $isPrint: boolean }>`
  flex-grow: 1;

  ${({ $isPrint }) =>
    !$isPrint &&
    css`
      @media screen and (max-width: ${MEDIUM_MAX_SIZE}px) {
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 2rem;
      }
    `}

  h3 {
    font-size: 1.143rem;
  }
`;

export const TableSection = styled(Box)<{ $isPrint: boolean }>`
  align-self: flex-end;
  display: flex;
  flex-grow: 1;
  flex: 1;
  flex-direction: column;
  position: relative;

  ${({ $isPrint }) =>
    !$isPrint &&
    css`
      @media screen and (max-width: ${MEDIUM_MAX_SIZE}px) {
        box-sizing: border-box;
        width: 100%;
      }
    `}
`;

export const TableContainer = styled.div<{ $isHidden: boolean; $isPrint: boolean }>`
  ${({ $isPrint }) =>
    !$isPrint &&
    css`
      @media screen and (max-width: ${MEDIUM_MAX_SIZE}px) {
        box-sizing: border-box;
        width: 100%;
      }
    `}

  ${({ $isHidden }) =>
    $isHidden &&
    css`
      filter: blur(4px);
      opacity: 0.15;
      pointer-events: none;
    `}

  > p {
    display: block;
    margin-top: 1rem;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
`;

export const CountryRow = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-bottom: 1px solid ${theme.colors.brandDarkTint90};
    display: flex;
    font-size: 0.875rem;
    justify-content: space-between;
    padding: 0.5rem;

    &.first {
      border-bottom: 1px solid ${theme.colors.brandDark};
      font-size: 1rem;
      font-weight: bold;
    }
  `}
`;

export const CountryIndex = styled.span`
  margin-right: 0.5rem;
`;

export const CountryFlag = styled.div<{ $isLarge: boolean; $countryCode: string }>`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/img/flags/${({ $countryCode }) => $countryCode.toLowerCase()}.svg');

  width: ${({ $isLarge }) => ($isLarge ? '36px' : '24px')};
  height: ${({ $isLarge }) => ($isLarge ? '27px' : '18px')};
`;

export const CountryName = styled.span`
  flex: 1;
  margin-left: 0.75rem;
`;

export const Percentage = styled.span``;

export const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1;
  min-height: 50svh;
`;
