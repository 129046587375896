import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({
    theme: {
      breakPoints: { mediumDevices },
    },
  }) => css`
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding: 5.5rem 2rem 2rem;
    position: relative;

    @media screen and (${mediumDevices.max}) {
      align-items: flex-start;
      flex-direction: column;
    }
  `}
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  gap: 1rem;

  .tiktok-logo {
    height: 2rem;
    width: 2rem;
  }
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;

  .icon {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const MetricsBox = styled.div`
  ${({
    theme: {
      breakPoints: { laptop, mediumDevices },
    },
  }) => css`
    align-items: flex-start;
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
    flex-shrink: 0;

    @media screen and (${laptop.max}) {
      flex-direction: column;
      align-items: flex-end;
    }

    @media screen and (${mediumDevices.max}) {
      align-items: flex-start;
      box-sizing: border-box;
      flex-direction: row;
      justify-content: flex-start;
      margin-left: -2rem;
      margin-top: 1rem;
      overflow-x: auto;
      padding: 0 2rem;
      scrollbar-width: none;
      width: calc(100% + 4rem);

      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  `}
`;

export const MetricsList = styled.div`
  ${({
    theme: {
      breakPoints: { laptop, mediumDevices },
    },
  }) => css`
    display: flex;
    gap: 2.5rem;

    .heading {
      line-height: 1em;
      margin-bottom: 0.5rem;
    }

    @media screen and (${mediumDevices.max}) {
      & :nth-last-child(1).metric-item {
        padding-right: 2rem;
      }
    }
  `}
`;

export const MetricItem = styled.div`
  flex-shrink: 0;
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  gap: 0.25rem;

  p {
    flex-shrink: 0;
    margin: 0;
  }

  .icon {
    height: 1rem;
    width: 1rem;
  }
`;

export const TrendArrow = styled.span<{ $isPositive: boolean }>`
  ${({ theme: { colors }, $isPositive }) => css`
    align-items: center;
    border-radius: 1rem;
    display: flex;
    justify-content: center;

    .icon {
      height: 1rem;
      width: 1rem;
    }

    ${$isPositive &&
    css`
      background: ${colors.semanticSuccessShade50};
    `}

    ${!$isPositive &&
    css`
      background: ${colors.semanticDangerShade20};
    `}
  `}
`;

export const Avatar = styled.div`
  ${({
    theme: {
      breakPoints: { laptop },
      colors,
    },
  }) => css`
    align-items: center;
    background: ${colors.brandPrimaryTint40};
    border: 2px solid ${colors.brandDarkAlpha20};
    border-radius: 0.5rem;
    display: flex;
    font-size: 2.514rem;
    flex-shrink: 0;
    height: 5.5rem;
    justify-content: center;
    width: 5.5rem;

    p {
      color: ${colors.brandDark};
      font-size: 2.625rem;
      font-weight: 500;
      text-transform: uppercase;
      margin: unset;
    }

    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }

    @media screen and (${laptop.max}) {
      order: -1;
    }
  `}
`;

export const WarningContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  position: absolute;
`;

export const WarningTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin: 0 5rem;

  svg {
    flex-shrink: 0;
  }
`;
