import { Heading, Spinner } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { SpinnerWrapper } from './index.styles';
import { ModuleErrorContainer } from '../../../index.styles';

type NoDataLoadingComponentProps = {
  noData: boolean;
  isLoading: boolean;
  title?: string;
};

export const NoDataLoadingComponent = ({ noData = false, isLoading = false, title = '' }: NoDataLoadingComponentProps) => {
  const { t } = useTranslation('reports');

  const emptyReport = t('empty_report');

  if (isLoading) {
    return (
      <SpinnerWrapper
        contentAlignment="center"
        contentJustify="center"
      >
        <Spinner size="lg" />
      </SpinnerWrapper>
    );
  }

  if (noData) {
    return (
      <ModuleErrorContainer>
        <Heading
          semantic="h6"
          variation="component-title_20"
          color="brandLight"
        >
          {title}
        </Heading>
        <div>{emptyReport}</div>
      </ModuleErrorContainer>
    );
  }

  return null;
};
