import styled, { css } from 'styled-components';
import { TooltipProps } from '.';

type ContentProps = Pick<TooltipProps, 'maxWidth'>;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Container = styled.span<{ noDecoration: boolean }>`
  ${({ noDecoration }) => css`
    cursor: ${noDecoration ? 'unset' : 'pointer'};
    display: inline-flex;
    position: relative;
    text-decoration: ${noDecoration ? 'none' : 'underline'};
  `}
`;

export const Content = styled.span<ContentProps>`
  ${({ theme: { borderRadius, colors, fontSizes }, maxWidth }) => css`
    align-items: center;
    background: ${colors.brandDarkTint10};
    border-radius: ${borderRadius.sm};
    cursor: default;
    color: ${colors.brandLight};
    display: block;
    font-size: ${fontSizes.s14};
    left: 0;
    letter-spacing: 0.005em;
    line-height: 1.285em;
    max-width: ${maxWidth ? '12rem' : 'unset'};
    padding: 0.25rem 0.5rem;
    position: absolute;
    text-decoration: none !important;
    display: inline-block;
    word-wrap: break-word;
    overflow-wrap: break-word;
    user-select: none;
    width: max-content;
    z-index: 1;
    white-space: normal;

    * {
      display: inline-flex;
    }
  `}
`;
