const fileNameExtensionRegex = /\.[a-z0-9]+$/i;

const extractFileNameExtension = (fileName: string) => {
  const match = fileName.match(fileNameExtensionRegex);

  return match ? match[0] : null;
};

const removeFileNameExtension = (fileName: string) => {
  return fileName.replace(fileNameExtensionRegex, '');
};

export default {
  extractFileNameExtension,
  removeFileNameExtension,
};
