import { Modal } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const ModalAddProfile = styled(Modal)`
  ${({ theme: { colors } }) => css`
    header h5 {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      gap: 0.5rem;
      .icon {
        font-size: 1.5rem;
      }
    }
    footer {
      background-color: ${colors.brandDarkTint95};
      font-size: 0.75rem;
      line-height: 1rem;
      gap: 1rem;
      icon {
        font-size: 1.5rem;
      }
      span {
        font-weight: 900;
      }
      p {
        margin: 0;
      }
    }
  `}
`;
