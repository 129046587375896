import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({
    theme: {
      breakPoints: { laptop, mediumDevices },
    },
  }) => css`
    display: flex;
    user-select: none;

    @media all and (${laptop.min}) {
      bottom: 1rem;
      color: #666;
      flex-direction: column;
      right: 0;
    }

    @media all and (${mediumDevices.max}) {
      flex-direction: row;
      gap: 1.5rem;
      margin-bottom: -1rem;
      order: -1;
      padding-top: 1.5rem;
      padding-left: 2rem;
    }
  `}
`;

export const Item = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;

export const ColorIndicator = styled.span`
  border-radius: 1rem;
  display: inline-flex;
  height: 0.75rem;
  width: 0.75rem;
`;

export const Label = styled.span`
  pointer-events: none;
  font-size: 11px;
`;
