import { Point } from '@nivo/line';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useTranslation } from 'react-i18next';
import { useTikTokReportContext } from '../../../context';

export const Tooltip = ({ point }: { point: Point }) => {
  const { userOffset } = useTikTokReportContext();
  const [
    {
      user: { language },
    },
  ] = useBridgeContext();
  const { t } = useTranslation('reports');
  const dt = new Date(point.data.x);

  dt.setHours(dt.getHours() + userOffset / 60);

  const formattedDate = new Intl.DateTimeFormat(language, { day: 'numeric', month: 'short' }).format(dt);

  return (
    <div
      style={{
        padding: '0.5rem',
        background: 'white',
        border: '1px solid #ccc',
        textAlign: 'left',
        fontSize: '.75rem',
        borderRadius: '.25rem',
      }}
    >
      <strong>{point.serieId}</strong>
      <div>
        {t('date')}: {formattedDate}
      </div>
      <div>
        {t('value')}: {point.data.y.toLocaleString()}
      </div>
    </div>
  );
};
