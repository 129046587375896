import { makeNumbersReadFriendly } from '@sendible/frontendv2/src/utils';

export const getTSpanGroups = (value: string, maxLineLength: number, maxLines = 2) => {
  const words = value.split(' ');

  type linesAcc = {
    lines: string[];
    currLine: string;
  };

  // reduces the words into lines of maxLineLength
  const assembleLines: linesAcc = words.reduce(
    (acc: linesAcc, word: string) => {
      // if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
      if ((word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word,
        };
      }

      // otherwise add the word to the current line
      return {
        ...acc,
        currLine: `${acc.currLine} ${word}`,
      };
    },
    { lines: [], currLine: '' }
  );

  // add the ending state of current line (the last line) to lines
  const allLines = assembleLines.lines.concat([assembleLines.currLine]);

  // for now, only take first 2 lines due to tick spacing and possible overflow
  const lines = allLines.slice(0, maxLines);
  const children: JSX.Element[] = [];
  let dy = 0;

  lines.forEach((lineText, i) => {
    children.push(
      <tspan
        x={0}
        dy={dy}
        key={i}
      >
        {
          // if on the second line, and that line's length is within 3 of the max length, add ellipsis
          i === 1 && allLines.length > 2 ? `${lineText.slice(0, maxLineLength - 3)}...` : lineText
        }
      </tspan>
    );
    // increment dy to render next line text below
    dy += 15;
  });

  return children;
};

export const getChartLeftMargin = (biggestValue: number, smallestValue: number): number => {
  const biggestValueString = makeNumbersReadFriendly(biggestValue);
  const smallestValueString = makeNumbersReadFriendly(smallestValue);
  const digits = biggestValueString.length > smallestValueString.length ? biggestValueString.length : smallestValueString.length;

  return 16 + 12 * digits;
};
