import { ComputedDatum } from '@nivo/swarmplot';
import { useTranslation } from 'react-i18next';

export const Tooltip = (node: ComputedDatum<UseGetTikTokPublishingReturnType['publishingBehaviour']['posts'][0]>) => {
  const { t } = useTranslation('reports');

  return (
    <div
      style={{
        padding: '1rem',
        fontSize: '.75rem',
        background: 'white',
        border: '1px solid #ccc',
        borderRadius: '.25rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <span>
        {t('date')}: {new Date(node.data.timestamp).toLocaleString()}
      </span>
      <span>
        {t('report_engagement_sidebar_link')}: {node.data.engagementLevel}
      </span>
    </div>
  );
};
