import { ResponsiveLine, Serie } from '@nivo/line';
import { ReactNode } from 'react';
import { LineChartContainer } from './index.styles';
import { NoDataLoadingComponent } from '../../../NoDataLoadingComponent';

type LineChartProps = {
  children?: ReactNode;
  color?: string;
  data: Serie[];
  isLoading: boolean;
  title?: string;
};

export const LineChart = ({ children, color = '#000000', data, isLoading = false, title = '' }: LineChartProps) => {
  if (!data?.length || isLoading) {
    return (
      <NoDataLoadingComponent
        noData={!data.length}
        isLoading={isLoading}
        title={title}
      />
    );
  }

  const lineDate = data[0].data;
  const maxTicks = 14;
  const tickCount = lineDate.length < maxTicks ? 'every 1 days' : `every ${Math.ceil(lineDate.length / maxTicks)} days`;

  return (
    <LineChartContainer>
      {children}
      <ResponsiveLine
        areaBaselineValue={0}
        areaOpacity={0.8}
        xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
        xFormat="time:%Y-%m-%d"
        axisBottom={{
          format: '%b %d',
          tickValues: tickCount,
        }}
        colors={[color]}
        data={data}
        enableArea
        enableGridX={false}
        enablePoints={false}
        lineWidth={1}
        legends={[
          {
            anchor: 'top-right',
            direction: 'column',
            translateX: -30,
            translateY: -25,
            itemWidth: 80,
            itemHeight: 20,
            symbolSize: 16,
            symbolShape: 'circle',
          },
        ]}
        margin={{ top: 50, right: 20, bottom: 50, left: 30 }}
      />
    </LineChartContainer>
  );
};
