import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)``;

export const Wrapper = styled(motion.div)``;

export const Content = styled(motion.div)<{ $maxWidth?: string }>`
  ${({ theme: { borderRadius, colors, fontSizes }, $maxWidth }) => css`
    background: ${colors.brandLight};
    border-radius: ${borderRadius.sm};
    padding: 0.5rem 1rem 0rem 1rem;
    position: absolute;
    z-index: 1;
    max-width: ${$maxWidth || '100%'};
    box-shadow: 0px 0.25rem 0.25rem 0px ${colors.brandDarkAlpha30}, 0px 0px 0px 1px ${colors.brandDarkAlpha10},
      0px 0.75rem 0.5rem -0.25rem ${colors.brandDarkAlpha15}, 0px 1px 1px 0px ${colors.brandDarkAlpha20};
  `}
`;
