import { Box } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';
import { HeaderContainer } from './components/Header/index.styles';
import { ReportWidth } from '../consts';

export const TrendArrow = styled.span<{ $isPositive: boolean }>`
  ${({ theme: { colors }, $isPositive }) => css`
    position: relative;
    margin-right: 0.3rem;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    ${$isPositive &&
    css`
      &::before {
        background-color: ${colors.semanticSuccessShade50};
      }
    `}
    ${!$isPositive &&
    css`
      &::before {
        background-color: ${colors.semanticDangerShade20};
      }
    `}
    svg {
      width: 20px;
      height: 20px;
      display: block;
      position: relative;
    }
  `}
`;

export const ReportContainer = styled.div`
  position: relative;
  width: 100%;
  background: #f1f0f8;
  min-height: 100%;

  &.print {
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: ${ReportWidth}px;
    font-family: sans-serif;
  }

  &.print ${HeaderContainer} {
    display: none !important;
  }
`;

export const ReportLoadingOverlay = styled.div`
  align-items: center;
  background: white;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: sticky;
  width: inherit;
  z-index: 9999;
`;

export const ReportMessageContainer = styled(Box)`
  box-sizing: border-box;
  justify-content: center;
  padding: 3rem;
  width: 100%;
`;

export const NoReportsContainer = styled(Box)`
  width: 100%;
  flex-direction: column;
  padding: 14px 20px;
`;

export const NoReportsErrorContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  gap: 2rem;

  svg {
    width: 3rem;
    height: 3rem;
  }
`;

export const Sections = styled.div`
  ${({ theme: { colors } }) => css`
    background: ${colors.brandLight};
    display: flex;
    flex-direction: column;
    gap: 5.5rem;
    padding-bottom: 2rem;
  `}
`;

export const Section = styled.section``;

export const SectionHeader = styled.div`
  margin-bottom: 1rem;
  padding: 0 2rem;
`;
