import { Container } from './index.styles';

interface DataItemProps {
  hideBorder: boolean;
  label: string;
  value: string | number;
}

export const DataItem = ({ hideBorder, label, value }: DataItemProps) => (
  <Container $hideBorder={hideBorder}>
    <p>
      <b>{label}</b>
    </p>
    <p>{value}</p>
  </Container>
);
