import { Button } from '@sendible/design-system';
import { Actions, Container, MinimiseButton } from './index.styles';
import { useBackgroundUploaderContext } from '../../context';

export const Header = () => {
  const { isMinimised, setIsMinimised, canBeClosed, closeUploader } = useBackgroundUploaderContext();

  return (
    <Container>
      <Actions>
        {canBeClosed && (
          <Button
            data-testid="close-backgrounduploader-button"
            onClick={() => {
              closeUploader();
            }}
            size={16}
            icon="close"
          />
        )}

        <MinimiseButton
          data-testid="minimise-backgrounduploader-button"
          rotateIconDegrees={isMinimised ? 180 : 0}
          onClick={() => {
            setIsMinimised(!isMinimised);
          }}
          size={16}
          icon="minimise_alt"
        />
      </Actions>
    </Container>
  );
};
