import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { cookie } from '@sendible/common';

export type ApiMethodsPropType = 'POST' | 'GET' | 'PUT' | 'DELETE';

export const getErrorFromFetchWithHeaders = (error: unknown) => {
  if (error instanceof AxiosError) {
    if (error.response?.data?.error?.message) {
      return error.response.data.error.message;
    }
  }

  return error;
};

function getHeadersForTestEnvironment(headers: object = {}) {
  const reverseProxyIP = cookie('proxysrv').get();

  return Object.assign(headers, process.env.SENDIBLE_ENV !== 'production' && reverseProxyIP ? { 'X-proxysrv': reverseProxyIP } : null);
}

function getUrlWithSearchParams(url: string, params?: object): string {
  const urlObject = new URL(url);

  if (params) {
    const combinedParams = {
      ...params,
      ...Object.fromEntries(urlObject.searchParams),
    };

    return `${url}?${new URLSearchParams(combinedParams).toString()}`;
  }

  return url;
}

export type FetchWithHeaderParamsType = {
  method: ApiMethodsPropType;
  url: string;
  params?: object;
  body?: object;
  headers?: object;
  onUploadProgress?: (p: number, e: Event, currentAbortController: AbortController) => void;
};

const fetchWithHeader = async ({ method, url, params, body, headers, onUploadProgress }: FetchWithHeaderParamsType) => {
  const currentAbortController = new AbortController();

  const config: AxiosRequestConfig = {
    method,
    url: getUrlWithSearchParams(url, params),
    headers: getHeadersForTestEnvironment(headers),
    data: body,
    signal: currentAbortController.signal,
    onUploadProgress:
      onUploadProgress &&
      ((progressEvent) => {
        let completedPercent = 0;

        if (progressEvent?.total) {
          completedPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }

        onUploadProgress(completedPercent, progressEvent, currentAbortController);
      }),
  };

  return axios(config)
    .then(async (response) => response.data)
    .then((result: any) => result);
};

export default fetchWithHeader;
