import styled, { css } from 'styled-components';

export const Container = styled.div<{ $isPrint: boolean }>`
  ${({
    theme: {
      breakPoints: { mediumDevices },
    },
    $isPrint,
  }) => css`
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 0 2rem;
    position: relative;

    ${!$isPrint &&
    css`
      @media all and (${mediumDevices.max}) {
        flex-direction: column;
        gap: 3rem;
      }
    `}
  `}
`;

export const Content = styled.div<{ $isPrint: boolean }>`
  ${({
    theme: {
      breakPoints: { mediumDevices },
    },
    $isPrint,
  }) => css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: calc(50% - 0.5rem);

    .report-warning {
      z-index: 1 !important;
    }

    ${!$isPrint &&
    css`
      @media all and (${mediumDevices.max}) {
        width: 100%;
      }
    `}
  `}
`;

export const BlurWrapper = styled.div<{ $isBlurry?: boolean }>`
  ${({ theme: { colors }, $isBlurry }) => css`
    ${$isBlurry &&
    css`
      position: relative;

      &::before {
        backdrop-filter: blur(0.4rem);
        background: ${colors.brandLightAlpha90};
        content: '';
        height: 105%;
        left: -2.5%;
        position: absolute;
        top: -2.5%;
        width: 105%;
        z-index: 1;
      }
    `}
  `}
`;

export const ChartContainer = styled.div`
  height: 40vh;
  height: 40svh;
`;

export const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
`;
