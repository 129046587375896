import { AnimationProps } from 'framer-motion';
import { ReactElement, UIEvent, useState } from 'react';
import { createPortal } from 'react-dom';
import { Backdrop, Container, Content, Footer, Header, Wrapper } from './index.styles';
import { Button } from '../Button';
import { Heading } from '../../atoms/Heading';
import { useKeyDown } from '../../utils/useKeyDown';

export const animations = ['appear', 'bottom', 'fade', 'left', 'right', 'top'] as const;
export const variations = ['fullscreen', 'small', 'medium', 'large'] as const;

type Animations = {
  [key in (typeof animations)[number]]: AnimationProps;
};

export interface ButtonProps {
  /**
   * Action event emmiter. Required.
   */
  action: () => void;
  /**
   * Action button label. Required.
   */
  actionLabel: string;
  /**
   * Disabled prop. Optional.
   */
  isDisabled?: boolean;
  /**
   * Set button to loading state. Optional.
   */
  isLoading?: boolean;
}

export interface ModalProps extends Component {
  /**
   * Animation type. Optional.
   */
  animation?: (typeof animations)[number];
  /**
   * Animation speed. Try values between 0 and 1 for better results. Optional.
   */
  animationSpeed?: number;
  /**
   * Aria close label. Optional.
   */
  ariaCloseLabel?: string;
  /**
   * Button details. Optional.
   */
  button?: ButtonProps;
  /**
   * Close event emmiter.
   * NOTE: Using both footerContent and
   * button props will cause button overlap
   */
  close: () => void;
  /**
   * Header title. Optional.
   */
  headingTitle?: string | ReactElement;
  /**
   * Hide header. Optional.
   */
  hideHeader?: boolean;
  /**
   * Do not close when pressing Esc key. Optional.
   */
  preventEscapeClose?: boolean;
  /**
   * Variation name. Optional.
   */
  variation?: (typeof variations)[number];
  /**
   * footer content. Optional.
   * NOTE: Using both footerContent and
   * button props will cause button overlap
   */
  footerContent?: string | ReactElement;
}

/**
 * Modal
 */
export const Modal = (props: ModalProps) => {
  const {
    animation = 'fade',
    animationSpeed = 0.3,
    ariaCloseLabel = '',
    button,
    children,
    className = '',
    close,
    customClass = '',
    headingTitle,
    hideHeader = false,
    preventEscapeClose = false,
    testId = 'modal',
    variation = 'fullscreen',
    footerContent,
  } = props;

  const [scrolled, setScrolled] = useState(false);

  const animationsSettings: Animations = {
    appear: {
      initial: { scale: 1.2, opacity: 0 },
      animate: { scale: 1, opacity: 1 },
      exit: { scale: 1.2, opacity: 0 },
    },
    bottom: {
      initial: { y: '100vh', opacity: 0 },
      animate: { y: 0, opacity: 1 },
      exit: { y: '100vh', opacity: 0 },
    },
    fade: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
    },
    left: {
      initial: { x: '-100vw', opacity: 0 },
      animate: { x: 0, opacity: 1 },
      exit: { x: '-100vw', opacity: 0 },
    },
    right: {
      initial: { x: '100vw', opacity: 0 },
      animate: { x: 0, opacity: 1 },
      exit: { x: '100vw', opacity: 0 },
    },
    top: {
      initial: { y: '-100vh', opacity: 0 },
      animate: { y: 0, opacity: 1 },
      exit: { y: '-100vh', opacity: 0 },
    },
  };

  const onScroll = (e: UIEvent) => {
    const target = e.target as HTMLElement;

    setScrolled(target.scrollTop > 0);
  };

  useKeyDown(() => {
    if (!preventEscapeClose) {
      close();
    }
  }, 'Escape');

  return createPortal(
    <>
      <Container
        className={`ds-modal ds-modal-${variation} ${customClass} ${className}`}
        data-testid={testId}
        key="ds-modal"
        $variation={variation}
      >
        <Wrapper
          $variation={variation}
          initial={animationsSettings[animation].initial}
          animate={animationsSettings[animation].animate}
          exit={animationsSettings[animation].exit}
          transition={{ ease: 'easeInOut', duration: 0.33 }}
          key="ds-modal-wrapper"
        >
          {!hideHeader && (
            <Header
              showLine={scrolled}
              $variation={variation}
            >
              <Heading
                align="left"
                color="brandDark"
                customClass="modal-heading"
                maxLines={1}
                semantic="h5"
                variation={variation === 'large' ? 'component-title_20' : 'component-title_14'}
              >
                {headingTitle}
              </Heading>
              <Button
                onClick={() => close && close()}
                icon="close"
                size={16}
                aria-label={ariaCloseLabel}
              />
            </Header>
          )}
          <Content
            $variation={variation}
            onScroll={onScroll}
          >
            {children}
          </Content>
          {button && (
            <Footer showLine={scrolled}>
              <Button
                appearance="primary"
                onClick={button.action}
                label={button.actionLabel}
                disabled={button.isDisabled}
                isLoading={button.isLoading}
                size={18}
                variation="fill"
              />
            </Footer>
          )}
          {footerContent && <Footer footerVisible>{footerContent}</Footer>}
        </Wrapper>
        <Backdrop
          data-testid="backdrop"
          onClick={() => close && close()}
          $variation={variation}
          transition={{ duration: animationSpeed * 1.66, ease: 'easeInOut' }}
          initial={animationsSettings.fade.initial}
          animate={animationsSettings.fade.animate}
          exit={animationsSettings.fade.exit}
          key="ds-modal-backdrop"
        />
      </Container>
    </>,
    document.getElementById('modal-root') as HTMLElement
  );
};
