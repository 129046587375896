import { urls } from '@sendible/common';

export const CampaignsUrls = {
  campaign: `${urls.baseUrl}/1.0/api/campaign`,
  campaigns: `${urls.baseUrl}/1.0/api/campaigns`,
  overview: `${urls.baseUrl}/1.0/api/campaign/overview`,
};

export const ReportsUrls = {
  quickReports: `${urls.baseUrl}/api/v2/quick_reports.json`,
  campaignAudienceGrowth: `${urls.baseUrl}/1.0/api/campaign/report/audience`,
  campaignOverview: `${urls.baseUrl}/1.0/api/campaign/report/overview`,
  campaignPosts: `${urls.baseUrl}/1.0/api/campaign/report/posts`,
  tiktok: `/0.2/tw/tiktok/report`,
  tiktokProfiles: `/api/v2/accounts.json`,
};

export const AiAssistUrls = {
  content: `${urls.baseUrl}/1.0/api/ai/content`,
  content_generator: `${urls.baseUrl}/1.0/api/ai/text_generation`,
};

export const ProfilesUrls = {
  blueskyAuthenticate: `${urls.baseUrl}/1.0/tw/bluesky/authenticate`,
};
