/* eslint-disable react/jsx-no-useless-fragment */
import { useRef, useMemo } from 'react';
import { Heading, Spinner } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useGetTikTokDemographics } from '@sendible/frontendv2/src/models/reports';
import {
  Container,
  ChartContainer,
  TableContainer,
  TableHeader,
  CountryRow,
  CountryFlag,
  CountryName,
  Percentage,
  CountryIndex,
  ChartSection,
  Content,
  SectionWrapper,
  TableSection,
  LoadingWrapper,
} from './index.styles';
import { Chart } from './components/Chart';
import { chartDummyData } from './mocks';
import { Summary } from '../Summary';
import { useReportsContext } from '../../../context';
import { Warning } from '../Warning';

export const Demographics = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isPrint } = useReportsContext();
  const { t } = useTranslation('reports');
  const [
    {
      user: { language },
    },
  ] = useBridgeContext();
  const { isLoading, data, isError, isRefetching } = useGetTikTokDemographics();

  const isGettingData = isLoading || isRefetching;

  const translations = useMemo(
    () => ({
      needs_more_data: t('needs_more_data'),
      demographicsHeader: t('demographics_header'),
      audienceByGender: t('audience_by_gender'),
      female: t('female'),
      male: t('male'),
      notSpecified: t('not_specified'),
      audienceByCountry: t('audience_by_country'),
      lifetimeViews: t('lifetime_views'),
      videoViewsByGender: t('video_views_by_gender'),
      lifetimeData: t('lifetime_data'),
    }),
    [t]
  );

  const audienceGendersNoData = !!(!data || !data.audienceGenders);
  const audienceCountriesNoData = !!(!data || !data.audienceCountries);

  const audienceGenders = audienceGendersNoData || isError ? chartDummyData.audienceGenders : data?.audienceGenders ?? [];
  const audienceCountries = audienceCountriesNoData || isError ? chartDummyData.audienceCountries : data?.audienceCountries ?? [];

  const audienceGendersByLabel = audienceGenders.reduce<Record<string, number>>((acc, item) => {
    acc[item.gender] = item.percentage;

    return acc;
  }, {});
  const regionNames = new Intl.DisplayNames([language], { type: 'region' });
  const summaryData = [
    {
      label: translations.female,
      value: audienceGendersByLabel.Female,
    },
    {
      label: translations.male,
      value: audienceGendersByLabel.Male,
    },
    {
      label: translations.notSpecified,
      value: audienceGendersByLabel['Not Specified'] || '-',
    },
  ];

  const loading = (
    <LoadingWrapper>
      <Spinner size="lg" />
    </LoadingWrapper>
  );

  const warning = (
    <>
      {isError ? (
        <Warning
          text={t('generic_report_error')}
          type="danger"
          color="semanticDangerShade20"
        />
      ) : (
        <Warning
          text={translations.needs_more_data}
          type="info"
          color="semanticInfo"
        />
      )}
    </>
  );

  return (
    <Container
      ref={containerRef}
      $isPrint={isPrint}
    >
      <>
        <Heading
          variation="component-title_14"
          semantic="h4"
        >
          {translations.videoViewsByGender}
        </Heading>
        <Content
          horizontalGap="s36"
          orientation="row"
          $isPrint={isPrint}
        >
          {isGettingData ? (
            loading
          ) : (
            <ChartSection
              orientation="column"
              $isPrint={isPrint}
            >
              {audienceGendersNoData && warning}
              <SectionWrapper $isHidden={audienceGendersNoData}>
                <ChartContainer>
                  <Chart audienceGenders={audienceGenders} />
                </ChartContainer>
                <Summary
                  title={translations.audienceByGender}
                  items={summaryData}
                />
              </SectionWrapper>
            </ChartSection>
          )}
          {isGettingData ? (
            loading
          ) : (
            <TableSection $isPrint={isPrint}>
              {audienceCountriesNoData && warning}
              <TableContainer
                $isHidden={audienceCountriesNoData}
                $isPrint={isPrint}
              >
                <TableHeader>
                  <span>{t('audience_by_country')} *</span>
                </TableHeader>
                {audienceCountries.map((country, index) => (
                  <CountryRow
                    key={index}
                    className={country.className}
                  >
                    <CountryIndex>{index + 1}.</CountryIndex>
                    <CountryFlag
                      $countryCode={country.countryCode}
                      $isLarge={!!country.className}
                    />
                    <CountryName>{country.name == 'Other' ? t('other') : regionNames.of(country.countryCode)}</CountryName>
                    <Percentage>{country.percentage}%</Percentage>
                  </CountryRow>
                ))}
                <p>* {translations.lifetimeViews}</p>
              </TableContainer>
            </TableSection>
          )}
        </Content>
      </>
    </Container>
  );
};
