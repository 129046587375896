/* eslint-disable @typescript-eslint/no-explicit-any */
import { initializeFaro, ErrorsInstrumentation } from '@grafana/faro-web-sdk';
import { faroIgnoreErrors } from './faroIgnoreErrors';

interface HandleSendErrorArgsType {
  errorMessage?: string | Event;
  error?: Error;
  data?: any;
}

const removeNumberFromString = (string: string) => string.replace(/\d/g, '');

const getValue = (value: string) => removeNumberFromString(value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, ''));

const getPageUrl = (string: string) => {
  const { pathname } = new URL(string);

  return removeNumberFromString(pathname);
};

const setupFaroErrorReporting = () => {
  if (process.env.SENDIBLE_ENV === 'production') {
    const faroUrl = `https://grafana-alloy.prod.sendible.com/collect`;
    const faroAppName = `frontend-faro`;

    const faro = initializeFaro({
      url: faroUrl,
      apiKey: 'glsa_qP6At1xfSP5mZwXBVjRRWE6KAVP01E01_e8313f77',
      app: {
        name: faroAppName,
        version: '1.0.0',
      },
      instrumentations: [new ErrorsInstrumentation()],
      trackResources: false,
      consoleInstrumentation: {
        consoleErrorAsLog: true,
      },
      dedupe: true,
      beforeSend: (item: any) => {
        const extraSessionData = {
          dashboard_version: String(window.dashboard_version) || '0',
          isTrialistUser: String(window.$current_user?.user_status?.toLowerCase().includes('on trial')) || 'undefined',
          original_url: item.meta.page.url,
          original_value: item.payload.value,
        };

        if (!item.meta.session) {
          item.meta.session = {
            attributes: {
              ...extraSessionData,
            },
          };
        } else {
          item.meta.session = {
            ...item.meta.session,
            attributes: {
              ...item.meta.session.attributes,
              ...extraSessionData,
            },
          };
        }

        item.meta.page.url = getPageUrl(item.meta.page.url || '');
        item.payload.value = getValue(item.payload.value || '');

        return item;
      },
    });

    const handleSendErrorToFaro = ({ error, errorMessage, data }: HandleSendErrorArgsType) => {
      const obj: unknown[] = [];

      if (errorMessage) obj.push(errorMessage);
      if (error?.stack && errorMessage !== error?.stack) obj.push(error.stack);

      const stringifiedErrors = Array.from(new Set(obj));

      const errorString = stringifiedErrors.join(' ');

      if (faroIgnoreErrors.map((e) => errorString.includes(e)).some((v) => v)) return;

      const config = data
        ? {
            context: {
              data,
            },
          }
        : {};

      faro.api.pushError(new Error(stringifiedErrors.reverse().join(', ')), config);
    };

    window.addEventListener(
      'reportErrorToFaro',
      function ({ detail }: any) {
        if (!detail) return;

        const data: HandleSendErrorArgsType = {
          error: detail.error,
          errorMessage: detail.error?.message || undefined,
          data: detail.data || undefined,
        };

        handleSendErrorToFaro(data);
      },
      { capture: true, once: false, passive: false }
    );

    window.onerror = function (errorMessage, fileName, lineNumber, column, error) {
      if (typeof error === 'undefined') {
        return true;
      }

      handleSendErrorToFaro({ errorMessage, error });
    };

    window.onunhandledrejection = function (error) {
      if (error.reason) handleSendErrorToFaro({ errorMessage: error.reason, error: undefined });
    };
  }
};

export default setupFaroErrorReporting;
