import { FollowersOnline } from './FollowersOnline';
import { PublishingContainer, PublishingWrapper } from './index.styles';
import { PublishingBehaviour } from './PublishingBehaviour';

export const Publishing = () => (
  <PublishingWrapper padding={['s24', 's24', 's24', 's24']}>
    <PublishingContainer
      contentJustify="space-between"
      orientation="row"
      fullWidth
    >
      <PublishingBehaviour />
      <FollowersOnline />
    </PublishingContainer>
  </PublishingWrapper>
);
