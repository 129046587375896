import { ReactNode, useRef, useState } from 'react';
import { useFloating, offset, flip, useFocus, autoUpdate, useInteractions } from '@floating-ui/react';
import { createPortal } from 'react-dom';
import { Container, Content, Wrapper } from './index.styles';
import { Positions } from '../../../theme';
import { useClickOutsideChecker } from '../../utils/useClickOutsideChecker';

export interface PopoverProps extends Component {
  /**
   * Popover information content. HTML content type. Required.
   */
  content: ReactNode;
  /**
   * Popover opening position. Optional.
   */
  placement?: Positions;
  /**
   * Use max width? Optional.
   */
  maxWidth?: string;
  /**
   * Trigger on click instead of hover. Optional.
   */
  useClick?: boolean;
}
/**
 * Popover
 */
export const Popover = (props: PopoverProps) => {
  const {
    children,
    className = '',
    content,
    customClass = '',
    placement = 'top',
    maxWidth = undefined,
    testId = 'Popover',
    useClick = false,
  } = props;
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const portalRoot = document.getElementById('popover-root');

  const { x, y, strategy, refs, context } = useFloating({
    open: isVisible,
    middleware: [offset(9), flip()],
    whileElementsMounted: autoUpdate,
    placement,
    strategy: 'fixed',
  });
  const focus = useFocus(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([focus]);

  useClickOutsideChecker(wrapperRef, (event) => {
    const target = event.target as HTMLElement;

    // If the target is the wrapper or the content, don't close the popover
    if (wrapperRef?.current?.contains(target) || contentRef?.current?.contains(target)) {
      return;
    }

    setIsVisible(false);
  });

  const toggleClickVisibility = () => {
    setIsVisible(!isVisible);
  };

  const childContainerProps = {
    ...(useClick && {
      onClick: toggleClickVisibility,
      onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.code === 'Space') {
          toggleClickVisibility();
        }
      },
    }),
    ...(!useClick && {
      onMouseEnter: () => setIsVisible(true),
      onMouseLeave: () => setIsVisible(false),
    }),
  };

  return (
    <Wrapper ref={wrapperRef}>
      <Container
        aria-describedby="Popover"
        className={`ds-Popover ${customClass} ${className}`}
        ref={refs.setReference}
        {...getReferenceProps()}
        data-testid={testId}
      >
        <div
          role="button"
          tabIndex={0}
          {...childContainerProps}
        >
          {children}
        </div>
        {isVisible &&
          portalRoot &&
          createPortal(
            <Content
              $maxWidth={maxWidth}
              data-testid={`${testId}-tip`}
              ref={refs.setFloating}
              style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
              {...getFloatingProps()}
            >
              <div ref={contentRef}>{content}</div>
            </Content>,
            portalRoot
          )}
      </Container>
    </Wrapper>
  );
};
