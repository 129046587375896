export const tikTokPerformanceMockData: UseGetTikTokPerformanceReturnType = {
  interactions: {
    bestEngagingDay: {
      date: '2024-09-23',
      current: 800,
      previous: 697,
      difference: 103,
      percentage: 9,
    },
    interactionsByDay: [
      {
        date: '2024-09-16',
        likes: 1233,
        comments: 320,
        shares: 157,
      },
      {
        date: '2024-09-17',
        likes: 918,
        comments: 444,
        shares: 234,
      },
      {
        date: '2024-09-18',
        likes: 1497,
        comments: 400,
        shares: 253,
      },
      {
        date: '2024-09-19',
        likes: 1040,
        comments: 302,
        shares: 189,
      },
      {
        date: '2024-09-20',
        likes: 1366,
        comments: 566,
        shares: 220,
      },
      {
        date: '2024-09-21',
        likes: 1601,
        comments: 501,
        shares: 310,
      },
      {
        date: '2024-09-22',
        likes: 1770,
        comments: 677,
        shares: 287,
      },
    ],
  },
  views: {
    bestPerformingDay: {
      date: '2024-09-23',
      current: 134,
      previous: 126,
      difference: 8,
      percentage: 9,
    },
    viewsByDay: [
      {
        date: '2024-09-16',
        views: 1233,
      },
      {
        date: '2024-09-17',
        views: 918,
      },
      {
        date: '2024-09-18',
        views: 1497,
      },
      {
        date: '2024-09-19',
        views: 1040,
      },
      {
        date: '2024-09-20',
        views: 1366,
      },
      {
        date: '2024-09-21',
        views: 1601,
      },
      {
        date: '2024-09-22',
        views: 1770,
      },
    ],
  },
};
