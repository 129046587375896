enum EVENTS {
  // Used for running jest tests
  EVENT_TEST = 'EVENT_TEST',
  // Internally used by CoreFacade to tell 360 that it needs to clean the page
  ROUTE_TO_V2 = 'ROUTE_TO_V2',
  // Internally used by 360 to signal when it finished loading.
  FINISH_360_BOOTSTRAP = 'FINISH_360_BOOTSTRAP',
  // To be used for triggering page changes across the dashboard
  CHANGE_PAGE = 'CHANGE_PAGE',
  // To be used when switching users
  SWITCH_USER = 'SWITCH_USER',
  // Internally used by the Bridge to observe changes in local storage
  LOCAL_STORAGE_CHANGED = 'LOCAL_STORAGE_CHANGED',
  // Used internally by V2 to communicate showing legacy styled toast notifications
  LEGACY_STYLED_TOAST_NOTIFICATION_SHOW = 'LEGACY_STYLED_TOAST_NOTIFICATION_SHOW',
  // Used internally by V2 to communicate showing  toast notifications
  TOAST_NOTIFICATION_SHOW = 'TOAST_NOTIFICATION_SHOW',
  // Used internally by V2 to communicate opening compose view
  OPEN_COMPOSE = 'OPEN_COMPOSE',
  // Open AI assist modal
  SHOW_AI_ASSIST = 'SHOW_AI_ASSIST',
  // Close AI assist modal
  CLOSE_AI_ASSIST = 'CLOSE_AI_ASSIST',
  // Send Generate content
  AI_ASSIST_GENERATED_CONTENT = 'AI_ASSIST_GENERATED_CONTENT',
  // Used internally by classic to communicate to V2 that the sidebar is open
  LEGACY_SIDEBAR_SHOW = 'LEGACY_SIDEBAR_SHOW',
  // Used by creative editor to communicate an exported image
  CREATIVE_EDITOR_IMAGE_EXPORT = 'CREATIVE_EDITOR_IMAGE_EXPORT',
  // Used by media system to display error message on compose
  ERROR_MESSAGE_FEEDBACK = 'ERROR_MESSAGE_FEEDBACK',
  // Used by dropdown compose to replace media
  MODAL_REPLACE_ATTACHED_MEDIA = 'MODAL_REPLACE_ATTACHED_MEDIA',
  // Used by media system to display error message on compose
  MODAL_CANCEL_REPLACE = 'MODAL_CANCEL_REPLACE',
}

export default EVENTS;
