import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${() => css`
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 27rem;
    min-width: 20rem;
    width: 100%;
  `}
`;

export const ItemList = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Item = styled.div`
  ${({ theme: { colors } }) => css`
    align-items: center;
    border-bottom: 1px solid ${colors.brandDarkTint90};
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0;
    width: 100%;
    .text {
      flex: 1;
    }
    .text:nth-child(2) {
      flex: 1;
      text-align: right;
    }
  `}
`;
