import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Serie } from '@nivo/line';
import { Box, Heading } from '@sendible/design-system/src';
import { reportErrorToFaro } from '@sendible/common';
import { useCampaignsReportsModel } from '@sendible/frontendv2/src/models/reports';
import { AudienceContainer, AudienceByDaySection, FullWidthBox, SectionHeader, SplitAndGrowthContainer } from './index.styles';
import { LineChart } from './components/LineChart';
import { BarChart } from './components/BarChart';
import { AudienceChangeComponent } from '../AudienceChange';
import { ReportColours, ReportTypes } from '../../../consts';
import { Header } from '../index.styles';
import { SupportedMedias } from '../SupportedMedias';
import { ModuleErrorContainer } from '../../../index.styles';

type AudienceGrowthProps = {
  id: number;
  startDate?: string | null;
  endDate?: string | null;
  defaultErrorMessage: string;
  type: ReportTypes;
};

type AudienceGrowthData = {
  audience: {
    date: string;
    audience: number;
  }[];
  profiles: {
    profile: string;
    audience: number;
  }[];
  audienceGained: {
    percentage: number;
    totalGained: number;
  };
};

const defaultData = {
  audience: [],
  profiles: [],
  audienceGained: {
    percentage: 0,
    totalGained: 0,
  },
};

export const AudienceGrowth = ({ id, startDate = null, endDate = null, type, defaultErrorMessage }: AudienceGrowthProps) => {
  const { t } = useTranslation('reports');
  const { getCampaignAudienceGrowth } = useCampaignsReportsModel();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<AudienceGrowthData>(defaultData);
  const { campaigns: campaignsReportColour } = ReportColours;
  let reportColour;

  if (type === ReportTypes.CAMPAIGN_REPORT_TYPE) {
    reportColour = campaignsReportColour;
  }

  const audienceChartTitleText = t('audience_chart_title');
  const audienceChartGrowthByDayText = t('audience_chart_growth_by_day');
  const audienceChartSplitText = t('audience_chart_split');

  const { audience, audienceGained, profiles } = data;
  const audienceDataForChart: Serie[] = audience.length
    ? [
        {
          id: t('audience_chart_title') || 'Audience Growth',
          data: audience.map(({ date, audience: audienceItem }) => {
            return {
              x: date,
              y: audienceItem,
            };
          }),
        },
      ]
    : [];
  const totalProfiles = profiles?.reduce((sum, item) => sum + item.audience, 0);
  const splitDataForChart = profiles.map((item) => ({
    profile: item.profile,
    value: item.audience,
  }));

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        setIsLoading(true);

        const results = await getCampaignAudienceGrowth(id);

        setData(results);

        setIsLoading(false);
      } catch (errorObject) {
        reportErrorToFaro(errorObject, 'Campaigns audienceGrowth');

        setError(defaultErrorMessage);

        setIsLoading(false);
      }
    };

    fetchCampaigns();
  }, [id]);

  if (error) {
    return (
      <Box margin={['s12', 's12', 's12', 's12']}>
        <ModuleErrorContainer>
          <Heading
            semantic="h6"
            variation="component-title_20"
            color="brandLight"
          >
            {audienceChartTitleText}
          </Heading>
          <div>{error}</div>
        </ModuleErrorContainer>
      </Box>
    );
  }

  return (
    <AudienceContainer
      margin={['s12', 's12', 's12', 's12']}
      padding={['s12', 's12', 's12', 's12']}
    >
      <Header>
        <Heading
          semantic="h6"
          variation="component-title_20"
        >
          {audienceChartTitleText}
        </Heading>
        <SupportedMedias />
      </Header>

      <AudienceByDaySection margin={['s0', 's0', 's24', 's0']}>
        <LineChart
          color={reportColour}
          data={audienceDataForChart}
          isLoading={isLoading}
          title={audienceChartGrowthByDayText}
        >
          <FullWidthBox>
            <SectionHeader variation="body_16">{audienceChartGrowthByDayText}</SectionHeader>
          </FullWidthBox>
        </LineChart>
      </AudienceByDaySection>

      <SplitAndGrowthContainer contentJustify="space-between">
        <BarChart
          color={reportColour}
          data={splitDataForChart}
          profileLength={profiles.length}
          total={totalProfiles}
          isLoading={isLoading}
          title={audienceChartSplitText}
        >
          <SectionHeader variation="body_16">{audienceChartSplitText}</SectionHeader>
        </BarChart>

        <AudienceChangeComponent
          audienceGainedPercentage={audienceGained?.percentage || null}
          color={reportColour}
          isLoading={isLoading}
          startDate={startDate}
          endDate={endDate}
        />
      </SplitAndGrowthContainer>
    </AudienceContainer>
  );
};
