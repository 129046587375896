import { Box } from '@sendible/design-system/src';
import styled from 'styled-components';

export const FullWidthBox = styled(Box)`
  box-sizing: border-box;
  width: 100%;
`;

export const PaginationContainer = styled(Box)`
  width: 100%;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 100%;
`;

export const SectionHeader = styled.h2`
  font-size: 1.75rem;
`;
