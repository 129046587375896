import { Box, Text } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const $smallScreenWidth = 1150;

export const PublishingWrapper = styled(Box)`
  background: white;
`;

export const PublishingContainer = styled(Box)`
  > div {
    width: 49.5%;

    @media screen and (max-width: ${$smallScreenWidth}px) {
      width: 100%;
      box-sizing: border-box;
    }
  }
`;

export const ChartHeader = styled(Text)`
  font-weight: 500;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Container = styled.div<{ $isHidden: boolean }>`
  box-sizing: border-box;
  gap: 0.5rem;
  margin-top: 1rem;
  transition: filter 0.2s, opacity 0.2s;
  width: 100%;

  ${({ $isHidden }) =>
    $isHidden &&
    css`
      filter: blur(4px);
      opacity: 0.15;
      pointer-events: none;
    `}

  @media screen and (max-width: ${$smallScreenWidth}px) {
    flex-direction: column;
    margin-top: 2rem;
  }
`;

export const ChartLegendContainer = styled(Box)`
  height: 40vh;
  height: 40svh;
  justify-content: space-between;
  align-items: end;
  width: 100%;
`;
export const ChartContainer = styled.div`
  width: calc(100% - 80px);
  height: 100%;
`;

export const StatsContainer = styled(Box)`
  flex-grow: 1;

  @media screen and (max-width: ${$smallScreenWidth}px) {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2rem;
  }

  h3 {
    font-size: 1.143rem;
  }
`;

export const TopMetrics = styled(Box)`
  max-width: 80%;

  @media screen and (max-width: ${$smallScreenWidth}px) {
    align-self: flex-end;
    width: unset;
  }
`;

export const StatsTable = styled(Box)`
  width: 100%;

  @media screen and (max-width: ${$smallScreenWidth}px) {
    width: unset;
  }
`;

export const StatRow = styled(Box)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.brandDarkTint80};
    width: 100%;
  `}
`;

export const LargeStatContainer = styled(Box)`
  width: 100%;

  @media screen and (max-width: ${$smallScreenWidth}px) {
    width: unset;
  }
`;

export const LargeStat = styled.div`
  font-size: 3rem;
  font-weight: 900;
  margin-bottom: 1rem;
`;

export const WarningContainer = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const WarningTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin: 0 5rem;

  svg {
    flex-shrink: 0;
  }
`;

export const TrendContainer = styled(Box)`
  flex-wrap: nowrap;
`;

export const PublishingBehaviourChartContainer = styled(Box)<{ $height: number }>`
  height: ${({ $height }) => $height}px;
  position: relative;
`;

export const ChartAndLegendWrapper = styled.div`
  flex-direction: row;
`;

export const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 40svh;
`;
