import styled, { css } from 'styled-components';
import { IconProps } from '.';

export const Container = styled.svg<IconProps>`
  ${({ theme: { colors }, color = 'brandPrimary', strokeWidth = 1.5, useStroke = true }) => css`
    ${useStroke &&
    css`
      fill: transparent;
      path {
        stroke: ${color && colors[color]};
        stroke-width: ${strokeWidth};
      }
    `}

    ${!useStroke &&
    css`
      fill: ${color && colors[color]};
    `}
  `}
`;
