import { Box } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const ReportsContainer = styled(Box)<{ $isPrint?: boolean }>`
  ${({ $isPrint = false }) => css`
    width: 100%;
    overflow-y: auto;
    position: relative;

    ${$isPrint &&
    css`
      overflow: clip;
    `}
  `}
`;
export const ModuleErrorContainer = styled(Box)`
  background-color: #000000;
  border-radius: 0.375rem;
  box-sizing: border-box;
  color: #ffffff;
  flex-direction: column;
  flex-grow: 1;
  font-weight: bold;
  justify-content: center;
  opacity: 0.5;
  padding: 1.875rem 1.875rem 2.5rem 1.875rem;
  width: inherit;

  div {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    min-height: 10rem;
    width: 100%;
  }
`;
