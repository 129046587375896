import { useEffect, RefObject } from 'react';

export const useClickOutsideChecker = (ref: RefObject<HTMLDivElement>, callback: (event: MouseEvent) => void) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) callback(event);
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);
};
