import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { DateTime } from 'luxon';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useToastNotificationContext } from '@sendible/design-system/src';
import { NotificationTypeEnum } from '@sendible/design-system/src/components/molecules/Toast';
import { useTranslation } from 'react-i18next';
import { reportErrorToFaro } from '@sendible/common';
import { defaultColor } from './components/NewCampaignModal/components';
import { DeleteModal } from './components';
import { pages } from '../../routes';
import { useCampaignsModel } from '../../models/campaigns';

type CampaignsContextType = {
  activeCampaignObject: CampaignsData;
  campaignsList: CampaignsData[];
  campaignsError: string | null;
  overviewError: string | null;
  isCampaignsLoading: boolean;
  isCampaignSaving: boolean;
  isEditionModalOpen: number | boolean;
  isSidebarVisible: boolean;
  saveCampaignFromObject: (campaign: CampaignsData) => void;
  setActiveCampaignObject: Dispatch<SetStateAction<CampaignsData>>;
  setIsEditionModalOpen: Dispatch<SetStateAction<number | boolean>>;
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>;
  overviewData?: CampaignsOverview;
  isOverviewLoading: boolean;
  setDeleteModalId: Dispatch<SetStateAction<number | null>>;
};

export const CampaignsContext = createContext({} as CampaignsContextType);

export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const LONG_API_DATE_FORMAT = 'yyyy/MM/dd HH:mm:ss ZZZ';

const defaultActiveCampaign: CampaignsData = {
  id: -1,
  name: '',
  startDate: null,
  endDate: null,
  status: 'active',
  color: defaultColor,
};

const getLuxonDateFromApiDate = (dateString: string | null) => {
  return !dateString ? null : DateTime.fromFormat(dateString, LONG_API_DATE_FORMAT);
};

export const updateResultsToUseLuxonDate = (results: CampaignsDataFromApi[]) => {
  return results.map((campaignObject) => {
    return {
      ...campaignObject,
      startDate: getLuxonDateFromApiDate(campaignObject.startDate),
      endDate: getLuxonDateFromApiDate(campaignObject.endDate),
    };
  });
};

export const CampaignsProvider = ({ children }: Component) => {
  const { id: paramIdString } = useParams();
  const { t } = useTranslation('campaigns');
  const paramId = paramIdString ? parseInt(paramIdString, 10) : null;
  const [
    {
      user: { username, isWhiteLabel },
    },
  ] = useBridgeContext();
  const { showToastNotification } = useToastNotificationContext();

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isCampaignsLoading, setIsCampaignsLoading] = useState(false);
  const [isOverviewLoading, setIsOverviewLoading] = useState(false);
  const [isCampaignSaving, setIsCampaignSaving] = useState(false);
  const [isEditionModalOpen, setIsEditionModalOpen] = useState<number | boolean>(false);
  const [campaignsList, setCampaignsList] = useState<CampaignsData[]>([]);
  const [activeCampaignObject, setActiveCampaignObject] = useState<CampaignsData>(defaultActiveCampaign);
  const [campaignsError, setCampaignsError] = useState<string | null>(null);
  const [overviewError, setOverviewError] = useState<string | null>(null);
  const [deleteModalId, setDeleteModalId] = useState<number | null>(null);

  const [overviewData, setOverviewData] = useState();
  const { getCampaignOverview } = useCampaignsModel();

  const { getCampaigns, saveCampaign, deleteCampaign } = useCampaignsModel();
  const navigate = useNavigate();

  const getCampaignFromId = (campaignsArray: CampaignsData[]) => {
    return campaignsArray.find((campaignObject) => paramId && campaignObject.id === paramId);
  };

  const getAndSetCampaigns = async () => {
    try {
      const results = await getCampaigns();

      const resultsWithLuxonDate = updateResultsToUseLuxonDate(results);
      const exisitingCampaignFromId = getCampaignFromId(resultsWithLuxonDate);

      setCampaignsList(resultsWithLuxonDate);

      if (exisitingCampaignFromId) {
        setActiveCampaignObject(exisitingCampaignFromId);
      } else if (resultsWithLuxonDate.length) {
        setActiveCampaignObject(resultsWithLuxonDate[0]);
        navigate(`${pages.Campaigns.base}/${resultsWithLuxonDate[0].id}`);
      } else {
        setActiveCampaignObject({
          ...defaultActiveCampaign,
        });
        navigate(pages.Campaigns.base);
      }
    } catch (errorObject) {
      if (errorObject instanceof Error) {
        reportErrorToFaro(errorObject, 'CampaignsContext getAndSetCampaigns');

        setCampaignsError(errorObject.message);
      }
    }

    setIsCampaignsLoading(false);
  };

  useEffect(() => {
    setIsCampaignsLoading(true);

    getAndSetCampaigns();
  }, [username]);

  useEffect(() => {
    const getCampaignOverviewData = async (id: number) => {
      setIsOverviewLoading(true);

      try {
        const result = await getCampaignOverview(id);

        setOverviewData(result);
      } catch (errorObject) {
        if (errorObject instanceof Error) {
          reportErrorToFaro(errorObject, 'CampaignsContext getAndSetCampaigns');

          setOverviewError(errorObject.message);
        }
      }
      setIsOverviewLoading(false);
    };

    setOverviewError(null);
    setCampaignsError(null);
    if (paramId) {
      const campaignFromId = getCampaignFromId(campaignsList);

      getCampaignOverviewData(paramId);

      if (campaignFromId) {
        setActiveCampaignObject(campaignFromId);
      } else {
        setActiveCampaignObject(defaultActiveCampaign);
      }
    }
  }, [paramId]);

  const saveCampaignFromObject = async (campaign: CampaignsData) => {
    try {
      const { color, name, startDate, endDate, status } = campaign;

      setIsCampaignSaving(true);

      const activeCampaignObjectForApi = {
        ...(color && { color }),
        ...(name && { name }),
        ...(startDate && { startDate: startDate.toFormat(API_DATE_FORMAT) }),
        ...(endDate && { endDate: endDate.toFormat(API_DATE_FORMAT) }),
        ...(paramId && { campaignId: paramId }),
        ...(status && { status }),
      };

      const result = await saveCampaign(activeCampaignObjectForApi, campaign.id ? 'PUT' : 'POST');

      setIsCampaignSaving(false);
      await getAndSetCampaigns();

      if (!campaign.id) {
        navigate(`${pages.Campaigns.base}/${result.id}`);
      }
      setIsEditionModalOpen(false);
    } catch (errorObject) {
      reportErrorToFaro(errorObject, 'CampaignsContext saveCampaignFromObject');

      showToastNotification({
        icon: true,
        notificationText: isWhiteLabel ? t('error_saving_wl') || '' : t('error_saving') || '',
        type: NotificationTypeEnum.Error,
        showCloseButton: true,
      });
      setIsCampaignSaving(false);
    }
  };

  const deleteCampaignById = async (campaignId?: number) => {
    try {
      if (!campaignId) return;
      await deleteCampaign(campaignId);
      await getAndSetCampaigns();
    } catch (error: unknown) {
      reportErrorToFaro(error, 'CampaignsContext deleteCampaignById');

      showToastNotification({
        icon: true,
        notificationText: isWhiteLabel ? t('error_deleting_wl') || '' : t('error_deleting') || '',
        type: NotificationTypeEnum.Error,
        showCloseButton: true,
      });
    }
  };

  return (
    <CampaignsContext.Provider
      value={{
        activeCampaignObject,
        campaignsList,
        campaignsError,
        overviewError,
        isCampaignsLoading,
        isCampaignSaving,
        isEditionModalOpen,
        isSidebarVisible,
        saveCampaignFromObject,
        setActiveCampaignObject,
        setIsEditionModalOpen,
        setIsSidebarVisible,
        overviewData,
        isOverviewLoading,
        setDeleteModalId,
      }}
    >
      <AnimatePresence>
        {deleteModalId && (
          <DeleteModal
            closeModalCallback={() => {
              setDeleteModalId(null);
            }}
            deleteCallback={() => {
              deleteCampaignById(deleteModalId || undefined);
              setDeleteModalId(null);
            }}
          />
        )}
      </AnimatePresence>
      {children}
    </CampaignsContext.Provider>
  );
};

export const useCampaignsContext = (): CampaignsContextType => useContext(CampaignsContext);
