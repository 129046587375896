import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { Button, Heading, Icon, Spinner } from '@sendible/design-system/src';
import { reportErrorToFaro } from '@sendible/common';
import { pages } from '@sendible/frontendv2/src/routes';
import { ErrorBoundary, ErrorState } from '@sendible/frontendv2/src/components';
import {
  CampaignsReportContainer,
  ReportMessageContainer,
  NotCampaignsContainer,
  NotCampaignsErrorContainer,
  ReportLoadingOverlay,
} from './index.styles';
import { AudienceGrowthCampaigns, PostsEngagement, TopPosts, Header, OverviewCampaigns } from './components';
import { ReportsList, ReportTypes } from '../consts';
import { useCampaignsModel } from '../../../models/campaigns';
import { updateResultsToUseLuxonDate } from '../../Campaigns/context';
import { useReportsContext } from '../context';

const CampaignsReport = () => {
  const { id: paramIdString } = useParams();
  const paramId = paramIdString ? parseInt(paramIdString, 10) : null;
  const { getCampaigns } = useCampaignsModel();
  const [isLoading, setIsLoading] = useState(false);
  const [campaignsList, setCampaignsList] = useState<CampaignsData[]>([]);
  const [currentCampaign, setCurrentCampaign] = useState<CampaignsData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { isPrint } = useReportsContext();
  const navigate = useNavigate();
  const [
    {
      user: { username, isWhiteLabel },
    },
  ] = useBridgeContext();

  const { t } = useTranslation('reports');

  const moduleErrorMessageSendible = `${t('error_if_persists')} ${t('contact_support')}.`;
  const moduleErrorMessageWl = `${t('error_if_persists_wl')}`;
  const moduleErrorMessage = isWhiteLabel ? moduleErrorMessageWl : moduleErrorMessageSendible;
  const emptyErrorMessage = t('empty_report');
  const notMoreDataErrorMessage = t('not_more_data_error_message');
  const campaignOverviewTitle = t('campaign_overview_title');
  const facebookPostEngagementTitle = t('facebook_post_engagement_title');
  const instagramPostEngagementTitle = t('instagram_post_engagement_title');
  const linkedinPostEngagementTitle = t('linkedin_post_engagemen_titlet');
  const topPostsTitle = t('top_posts_title');

  const noReportsAvailableText = t('no_reports_available');
  const campaignsReportText = t('campaigns_report');

  const getCampaignFromId = (campaignsArray: CampaignsData[]) => {
    return campaignsArray.find((campaignObject) => paramId && campaignObject.id === paramId);
  };

  const handleReportRouteByCampaignObject = (campaignObject: CampaignsData | undefined, campaignsListArray: CampaignsData[]) => {
    if (campaignObject) {
      setCurrentCampaign(campaignObject);
      if (campaignObject.id) {
        window.pendo.track('[Report hub] Report views', {
          id: campaignObject.id,
          type: 'quick report',
          network: 'campaign',
        });
      }

      navigate(`${pages.Measure.campaigns}/${campaignObject.id}`);
    } else if (campaignsListArray.length) {
      setCurrentCampaign(campaignsListArray[0]);
      navigate(`${pages.Measure.campaigns}/${campaignsListArray[0].id}`);
    } else {
      setCurrentCampaign(null);
    }
  };

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        setIsLoading(true);

        const results = await getCampaigns();
        const resultsWithLuxonDate = updateResultsToUseLuxonDate(results);

        setCampaignsList(resultsWithLuxonDate);

        const exisitingCampaignFromId = getCampaignFromId(resultsWithLuxonDate);

        handleReportRouteByCampaignObject(exisitingCampaignFromId, results);
        setIsLoading(false);
      } catch (errorObject) {
        if (errorObject instanceof Error) {
          reportErrorToFaro(errorObject, 'CampaignsReport fetchCampaigns');

          setError(errorObject.message);
        } else {
          setError('');
        }

        setIsLoading(false);
      }
    };

    fetchCampaigns();
  }, [username]);

  useEffect(() => {
    const exisitingCampaignFromId = getCampaignFromId(campaignsList);

    handleReportRouteByCampaignObject(exisitingCampaignFromId, campaignsList);
  }, [paramId]);

  if (error !== null) {
    return (
      <ErrorState
        errorMessage={error}
        translationNamespace="reports"
      />
    );
  }

  if (isLoading) {
    return (
      <ReportMessageContainer>
        <Spinner size="lg" />
      </ReportMessageContainer>
    );
  }

  if (!currentCampaign) {
    return (
      <NotCampaignsContainer>
        <Heading semantic="h1">{campaignsReportText}</Heading>
        <NotCampaignsErrorContainer>
          <Icon name="megaphone" />
          <Heading semantic="h4">{noReportsAvailableText}</Heading>
          <Button
            onClick={() => {
              navigate(`${pages.Campaigns.base}`);
            }}
            label="Create Campaign"
            size={14}
            appearance="primary"
            variation="fill"
          />
        </NotCampaignsErrorContainer>
      </NotCampaignsContainer>
    );
  }

  const reportsList: ReportsList[] = campaignsList.map(({ name = '', id = 0 }) => {
    return { name, id };
  });

  const friendlyDateFormat = 'dd LLL yyyy';
  const friendlyStartDate = currentCampaign.startDate ? currentCampaign.startDate.toFormat(friendlyDateFormat) : null;
  const friendlyEndDate = currentCampaign.endDate ? currentCampaign.endDate.toFormat(friendlyDateFormat) : null;

  return (
    <>
      {isPrint && (
        <ReportLoadingOverlay>
          <Spinner size="lg" />
        </ReportLoadingOverlay>
      )}
      <ErrorBoundary translationNamespace="reports">
        <CampaignsReportContainer className={`v2-report-container ${isPrint ? 'print' : ''}`}>
          <Header
            reportsList={reportsList}
            currentReportId={currentCampaign.id as number}
          />
          <OverviewCampaigns
            title={campaignOverviewTitle}
            type={pages.Measure.campaigns}
            id={currentCampaign.id || null}
            defaultErrorMessage={moduleErrorMessage}
            emptyErrorMessage={emptyErrorMessage}
          />
          <AudienceGrowthCampaigns
            id={currentCampaign.id as number}
            startDate={friendlyStartDate}
            endDate={friendlyEndDate}
            defaultErrorMessage={moduleErrorMessage}
            type={ReportTypes.CAMPAIGN_REPORT_TYPE}
          />
          <TopPosts
            title={topPostsTitle}
            type={ReportTypes.CAMPAIGN_REPORT_TYPE}
            id={currentCampaign.id || null}
            defaultErrorMessage={moduleErrorMessage}
            emptyErrorMessage={emptyErrorMessage}
            notMoreDataErrorMessage={notMoreDataErrorMessage}
          />
          <PostsEngagement
            title={facebookPostEngagementTitle}
            socialNetwork="facebook"
            type={ReportTypes.CAMPAIGN_REPORT_TYPE}
            id={currentCampaign.id || null}
            defaultErrorMessage={moduleErrorMessage}
            notMoreDataErrorMessage={notMoreDataErrorMessage}
          />
          <PostsEngagement
            title={instagramPostEngagementTitle}
            socialNetwork="instagram"
            type={ReportTypes.CAMPAIGN_REPORT_TYPE}
            id={currentCampaign.id || null}
            defaultErrorMessage={moduleErrorMessage}
            notMoreDataErrorMessage={notMoreDataErrorMessage}
          />
          <PostsEngagement
            title={linkedinPostEngagementTitle}
            socialNetwork="linkedin"
            type={ReportTypes.CAMPAIGN_REPORT_TYPE}
            id={currentCampaign.id || null}
            defaultErrorMessage={moduleErrorMessage}
            notMoreDataErrorMessage={notMoreDataErrorMessage}
          />
        </CampaignsReportContainer>
      </ErrorBoundary>
    </>
  );
};

export default CampaignsReport;
