export default {
  minLengthMessage: 'At least 8 characters',
  uppercaseMessage: 'One uppercase character (A-Z)',
  lowercaseMessage: 'One lowercase character (a-z)',
  numberMessage: 'One number (0-9)',
  specialCharMessage: 'One symbol (~`!@#$%^&*()_-+={[}]|\\:;"\'<,>.?/)',

  validateEmail: (email: string) => {
    // Regex validate that it doesnt contains other languages characters like japanese or arabic
    /* eslint-disable */
    const EMAIL_REGEX_MATCH =
      /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
    /* eslint-enable */

    if (typeof email === 'undefined' || !email) {
      return false;
    }

    return EMAIL_REGEX_MATCH.test(email);
  },

  validatePassword: (password: string) => {
    const MIN_PASSWORD_LENGTH = 8;

    let isValid = true;
    let hasNumber = true;
    let hasUpperCase = true;
    let hasLowerCase = true;
    let hasSpecialChar = true;
    let hasMinLength = true;

    if (password.length < MIN_PASSWORD_LENGTH) {
      isValid = false;
      hasMinLength = false;
    }
    if (password.search(/[a-z]/) < 0) {
      isValid = false;
      hasLowerCase = false;
    }
    if (password.search(/[A-Z]/) < 0) {
      isValid = false;
      hasUpperCase = false;
    }
    if (password.search(/[0-9]/i) < 0) {
      isValid = false;
      hasNumber = false;
    }
    if (password.search(/[~`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/i) < 0) {
      isValid = false;
      hasSpecialChar = false;
    }

    return {
      isInvalid: !isValid,
      hasMinLength,
      hasLowerCase,
      hasUpperCase,
      hasNumber,
      hasSpecialChar,
    };
  },

  validatePostMessageSource: (event: MessageEvent) => {
    const { origin, data } = event;
    const allowedDomains = ['app.sendblur.com', 'login.sendblur', 'localhost', 'sendilab.com', 'sendible.com'];

    if (
      (origin && allowedDomains.some((domain) => origin.includes(domain))) ||
      (typeof data === 'object' && data.origin && data.origin.includes('sendible.com')) ||
      (typeof event.data === 'string' && event.data.includes('feature-check'))
    ) {
      return true;
    }

    return false;
  },
};
